import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { scrollToTop } from '../../utils/scrollToTop'

function Bigdata(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="bigdata">
        <Container>
          <div className="esewa-header clearfix">
            <div className=" logo ">
              <img src="/img/logos/logo-05.png" alt="" />
            </div>

            <div className=" media">
              <ul className=" follow-us">
                <li className="facebook">
                  <a
                    href="https://www.facebook.com/extensodata"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
                {/* <li className="twitter">
                      <a href="https://twitter.com/f1soft">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li> */}
                <li className="linkedin">
                  <a
                    href="https://www.linkedin.com/company/extensodata/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </li>
                <li className="website">
                  <a
                    href="http://www.extensodata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                    <img src="/img/www.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="esewa-body">
            <p className="text-muted">
              {/* Established in 2018, eXtenso Data is a data analytics company that
              equips various businesses with the tools required to optimize
              daily operations, lower costs and identify new opportunities. The
              company has solutions that analyze existing business data to
              reveal patterns, trends, and prospects that are extremely valuable
              for making decisions, mitigating risks, meeting challenges and
              planning. Extenso Data specializes in Artificial Intelligence,
              Data Analytics, Predictive Analytics and Big Data. */}
              Established in 2018, eXtenso Data is a data analytics company that 
              equips various businesses with the tools required to optimize daily 
              operations, lower costs, and identify new opportunities. The company 
              offers solutions that analyze existing business data to reveal patterns, 
              trends, and prospects that are extremely valuable for decision-making, 
              risk mitigation, meeting challenges, and planning. eXtenso Data 
              specializes in Artificial Intelligence, Data Analytics, Predictive 
              Analytics, and Big Data.
              <br />
              <br /> 
              {/* Extenso Data provides its services to banks and financial
              institutions, insurance, telecommunications, media and technology,
              retail, asset management, and hospitality. Business analysis,
              future forecasting, process optimization, data engineering, and
              process automation are some of the core services provided by the
              company. */}
              eXtenso Data provides its services to banks and financial institutions, 
              insurance, telecommunications, media and technology, retail, asset 
              management, and hospitality. Business analysis, future forecasting, 
              process optimization, data engineering, and process automation are some 
              of the core services provided by the company
              <br />
            </p>
          </div>
          <div className="img-collection">
            <div className=" position-relative">
              <h5>Services</h5>
              <div className="underline">
                {' '}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </div>
            </div>
            <Row>
              <Col md="3">
                <img src="/img/business/big-data/1.png" alt="" />
              </Col>
              <Col md="3">
                <img src="/img/business/big-data/2.png" alt="" />
              </Col>
              <Col md="3">
                <img src="/img/business/big-data/3.png" alt="" />
              </Col>
              <Col md="3">
                <img src="/img/business/big-data/4.png" alt="" />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img src="/img/business/big-data/5.png" alt="" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
export default Bigdata
