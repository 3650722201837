import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
function FeatureList() {
  return (
    <div className="feature-list d-md-none">
      <Row>
        <Col md="12">
          <ul>
            <li>
              <div className="catalogue">
                <img src="/img/homepage/bg_banksmart.jpg" alt="" />
                <div className="position-relative">
                  <div className="position-absolute">
                    <Link to="business/fintech" className="features ">
                      Fintech for the banked
                    </Link>
                    {/* <p className='feature-text '>
                      Connecting people to make life more funConnecting people
                      to make life more fun
                    </p> */}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="catalogue">
                <img src="/img/homepage/bg_healthcare.jpg" alt="" />
                <div className="position-relative">
                  <div className="position-absolute">
                    <Link to="business/healthtech" className="features ">
                      Informatics for Improved Healthcare
                    </Link>
                    {/* <p className='feature-text '>
                      Connecting people to make life more funConnecting people
                      to make life more fun
                    </p> */}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="catalogue">
                <img src="/img/homepage/bg_bigdata.jpg" alt="" />
                <div className="position-relative">
                  <div className="position-absolute">
                    <Link to="business/bigdata" className="features">
                      Analytics, Big Data & Forecasting
                    </Link>
                    {/* <p className='feature-text '>
                      Connecting people to make life more funConnecting people
                      to make life more fun
                    </p> */}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  )
}

export default FeatureList
