import React from 'react'
import { Row, Col } from 'reactstrap'
import './connectinguser.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Transition } from 'react-transition-group'
import { Link } from 'react-router-dom'

function ConnectingUser() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [transitionFirst, setTransitionFirst] = useState(false)
  const [transitionSecond, settransitionSecond] = useState(false)
  const [transitionThird, settransitionThird] = useState(false)
  //  const [transitionForth, settransitionForth] = useState(false);

  const duration = 100
  // text header transition
  const headerTextStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    position: 'absolute',
    top: 0
  }
  const headerTextTransition = {
    entering: { top: -15 },
    entered: { top: -85 },
    exiting: { top: -85 },
    exited: { top: -15 }
  }
  //  text-transition
  const defaultTextStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    position: 'absolute',
    height: 0,
    opacity: 0,
    top: 0
  }
  const defaultTextTransition = {
    entering: { opacity: 0, height: 0, top: -60 },
    entered: { opacity: 1, height: 0, top: -86 },
    exiting: { opacity: 1, height: 0, top: -86 },
    exited: { opacity: 0, height: 0, top: -60 }
  }
  // arrow transition
  const defaultArrowStyle = {
    transition: 300 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    marginLeft: 5,
    opacity: 0
  }
  const defaultArrowTransition = {
    entering: {
      marginLeft: 10,
      opacity: 0
    },
    entered: {
      marginLeft: 40,
      opacity: 1
    },
    exiting: {
      marginLeft: 35,
      opacity: 0
    },
    exited: {
      marginLeft: 5,
      opacity: 0
    }
  }

  return (
    <div className="connecting-user ">
      <Row>
        {/* <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => setTransitionFirst(true)}
          onMouseLeave={() => setTransitionFirst(false)}
        >
          <img src="/img/homepage/bg_banksmart.jpg" alt="" />
          <Transition in={transitionFirst} timeout={duration}>
            {state => {
              return (
                <Link to="/business/fintech">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Fintech for the banked
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        BankXP, our omni-channel banking solution, serves over 19M
                        people through 50+ banks
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div>
        <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => settransitionSecond(true)}
          onMouseLeave={() => settransitionSecond(false)}
        >
          <img src="/img/homepage/bg_esewa.jpg" alt="" />
          <Transition in={transitionSecond} timeout={duration}>
            {state => {
              return (
                <Link to="/business/digitalwallet">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Digital wallet for the underbanked
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        With 200K+ agents across Nepal, eSewa reaches out to
                        every part of the country
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div> */}
      </Row>
      <Row className="row-no-padding">
        {/* <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => settransitionThird(true)}
          onMouseLeave={() => settransitionThird(false)}
        >
          <img src="/img/homepage/bg_fonepay.jpg" alt="" />
          <Transition in={transitionThird} timeout={duration}>
            {state => {
              return (
                <Link to="/business/paymentprocessing">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Network for interoperability
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Fonepay connects consumers, banks and businesses into an
                        interoperable network to facilitate payments and fund
                        transfers between them
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div>
        <div className='div'> */}
        {/* <Col
          className="figure"
          onMouseEnter={() => settransitionForth(true)}
          onMouseLeave={() => settransitionForth(false)}
        >
          <img src="/img/homepage/35.png" alt="" />
          <Transition in={transitionForth} timeout={duration}>
            {(state) => {
              return (
                <div className="position-relative">
                  <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                    <h5
                      className="h5-flex"
                      style={{
                        ...headerTextStyle,
                        ...headerTextTransition[state],
                        ...{ position: 'relative' },
                      }}
                    >
                      Connecting User
                      <div className="position-relative">
                        <div
                          className="angle-button"
                          style={{
                            ...defaultArrowStyle,
                            ...defaultArrowTransition[state],
                            ...{ position: 'relative' },
                          }}
                        >
                          <FontAwesomeIcon icon="angle-double-right" />
                        </div>
                      </div>
                    </h5>

                    <p
                      className=""
                      style={{
                        ...defaultTextStyle,
                        ...defaultTextTransition[state],
                        ...{ position: 'relative' },
                      }}
                    >
                      We have always attached great importance to the
                      development of employees, not only designing a
                      professional and management dual-channel career
                    </p>
                  </div>
                </div>
              );
            }}
          </Transition>
        </Col> */}
        {/* </div> */}
      </Row>
    </div>
  )
}

export default ConnectingUser
