import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { scrollToTop } from '../../utils/scrollToTop'
// import { Colxx } from '../../components/common/CustomBootstrap';

function Company(props) {
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="ecosystem">
        <Container>
          <div className="system-introduction text-muted">
            {/* The digital financial penetration rate in Nepal is chronically low
            with the best guesstimate being ~2-3% of the total transaction
            volume in a US$ 30 billion economy. Although the banking sector is
            becoming increasingly digitized, ~51% of the country’s population is
            still unbanked. However, almost 70% of the Nepali population are
            under 40 years of age and educated. Mobile phone penetration rate is
            over 130% and the price of mobile internet (data) has reduced 20
            fold in the past 5 years making it more affordable and accessible to
            the majority.
            <br />
            <br /> */}
            <b>
              At F1Soft, we believe that Nepal is nearing a digital inflection
              point that could save billions in lost transaction costs and
              economic rents through enhanced transparency. Moreover, riding on
              technology to provide easy and affordable access to financial
              services is one of the major contributors to economic prosperity,
              especially for a land-locked and geopolitically challenged nation
              like Nepal.
            </b>
            <br />
            <br />
            F1Soft has been continuously building an ecosystem of digital
            products and services to enable Nepal’s digital transformation. All
            of our solutions are designed to fit into and contribute to this
            ecosystem, be it as a core asset or as a value addition. The
            ecosystem continues to evolve with the addition of new avenues,
            innovations and technologies. But it shall always exist to realize
            our vision of becoming a household brand that every Nepali uses to
            meet their digital financial needs, every single day.
          </div>
        </Container>
        <div className="core-fintech">
          <Container>
            <Row>
              <Col md="6">
                <h5>Core Fintech Assets</h5>
                {/* <ul className="text-muted">
                  The heart of the ecosystem is our Fintech asset base. This
                  segment consists of fintech solutions that we have been
                  working on since our inception
                  <li>
                    F1Soft, as an individual company, develops fintech products
                    that are used by banks; mobile banking and internet banking
                    being the ones creating the most visible impact.
                  </li>
                  <li>
                    eSewa is Nepal’s first and leading digital wallet and
                    through its extensive agent network, provides digital
                    financial services to the under-banked and unbanked
                    population segments. eSewa’s web/mobile app and 80K+ mobile
                    money agents spread across the country have unparalleled
                    reach and impact.
                  </li>
                  <li>
                    Fonepay is an interoperable payment network that connects
                    consumers and businesses across 50+ banks and financial
                    institutions enabling real-time digital transactions.
                  </li>
                  <li>
                    eXtensoData specializes in big data and AI and helps analyze
                    the data processed by the ecosystem to optimize our
                    solutions and their performance.
                  </li>
                </ul> */}
                <ul className="text-muted">
                  {/* At the core of our ecosystem lies our FinTech asset base, consisting of a range of fintech solutions that we have 
                  developed over the years. 
                  <br />
                  <br />

                  F1Soft International is committed to creating innovative and effective fintech products that are widely used by 
                  banks, with mobile banking and internet banking being the ones that have made the most visible impact.
                  <br />
                  <br /> */}

                  At the core of our ecosystem lies our fintech asset base, consisting of a range of solutions developed over the 
                  years by F1Soft International.
                  <br />
                  <br />

                  F1Soft is committed to creating innovative and effective fintech products widely used by banks, with a focus on 
                  mobile banking and internet banking, which have made the most visible impact.
                  <br />
                  <br />

                  {/* One of our flagship products is eSewa, a widely-used digital wallet. eSewa has an extensive agent network that 
                  provides digital financial services to under-banked and unbanked segments of the population. With its web and mobile 
                  app, and more than 200k+ agents, eSewa has unparalleled reach and impact, bringing digital financial 
                  services to those who previously lacked access. */}
                  One flagship product is eSewa, a widely-used digital wallet with an extensive agent network that provides digital 
                  financial services to under-banked and unbanked populations. With its web and mobile app, along with over 200k+ 
                  agents, eSewa has unparalleled reach, bringing digital financial services to those who previously lacked access.
                  <br />
                  <br />

                  {/* Another essential part of our FinTech asset base is Fonepay, an interoperable payment network that connects consumers 
                  and businesses across more than 60 banks and financial institutions, enabling real-time digital transactions. 
                  Fonepay's innovative payment solutions have made it easier to conduct transactions digitally, bringing more people 
                  into the financial mainstream. */}
                  Another essential part of our fintech asset base is Fonepay, an interoperable payment network connecting consumers 
                  and businesses across more than 60 banks and financial institutions, enabling real-time digital transactions. 
                  Fonepay's innovative payment solutions have made digital transactions more accessible, bringing more people into the 
                  financial mainstream.
                  <br />
                  <br />

                  {/* Finally, we have eXtensoData, which specializes in big data and AI. eXtensoData helps us analyze the data processed 
                  by the ecosystem to optimize our solutions and their performance. This allows us to continuously improve our fintech 
                  products and services to better meet the needs of our customers. */}
                  Finally, we have eXtensoData, specializing in big data and artificial intelligence. eXtensoData helps us analyze 
                  the data processed by the ecosystem to optimize our solutions and their performance. This allows us to continuously 
                  improve fintech products and services to better meet customer needs.
                  <br />
                  <br />

                  {/* Together, our Fintech assets form the backbone of our ecosystem, providing the foundation upon which we can build 
                  innovative new solutions that help drive Nepal's digital transformation forward. */}
                  Together, our fintech assets form the backbone of our ecosystem, providing the foundation upon which we can build 
                  innovative new solutions that help drive Nepal's digital transformation forward.

                </ul>
              </Col>
              <Col md="6">
                <img src="/img/about/ecosystem/fintech-assets-202464.png" alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="investment-arm d-none d-md-block">
          <Container>
            <Row>
              <Col md="6">
                <div className="investment-logos">
                  <Row>
                    <div className="col-6">
                      <img
                        src="/img/about/ecosystem/logo-cogent-health.png"
                        alt=""
                      />
                    </div>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-02.png" alt="" />
                    </div>
                  </Row>
                  <Row>
                    <Col md="6">
                      {/* <img src="/img/about/ecosystem/logo-03.png" alt="" /> */}
                      <img src="/img/about/ecosystem/logo-foneinsure.png" alt="" />
                    </Col>
                    <Col md="6">
                      {/* <img src="/img/about/ecosystem/logo-04.png" alt="" /> */}
                      <img src="/img/about/ecosystem/logo-08.png" alt="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <img src="/img/about/ecosystem/logo-05.png" alt="" />
                    </Col>
                    <Col md="6">
                      <img src="/img/about/ecosystem/logo-06.png" alt="" />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="6">
                      <img src="/img/about/ecosystem/logo-07.png" alt="" />
                    </Col>
                    <Col md="6">
                      <img src="/img/about/ecosystem/logo-08.png" alt="" />
                    </Col>
                  </Row> */}
                </div>
              </Col>
              <Col md="6">
                <h5 className='text-center'>Investment Arm</h5>
                <p className="text-muted text-left">
                  {/* One of the main challenges to the adoption of digital finance
                  in Nepal is the lack of digitization across various
                  industries/sectors and hence the inability to process digital
                  transactions. For instance, it is not possible to purchase bus
                  tickets digitally until the ticketing system itself is
                  digitized. */}
                  One of the main challenges to the adoption of digital finance in 
                  Nepal is the lack of digitization across various industries/sectors, 
                  resulting in the inability to process digital transactions. For 
                  instance, it is not possible to purchase bus tickets digitally until 
                  the ticketing system itself is digitized.
                  <br />
                  <br /> 
                  {/* Realizing this gap, the F1Soft Group has invested in
                  various tech companies each of which caters to the digital
                  needs of a specific sector. Cogent Health builds EMR and ERP
                  solutions for hospitals while Diyalo technologies focuses on
                  ticketing systems and utility services. DarshanTech provides
                  core systems tailored for the insurance sector while eSewa
                  Money Transfer, our latest venture, looks to digitize the
                  remittance industry. */}
                  Recognizing this gap, the F1Soft Group has invested in various tech 
                  companies, each catering to the digital needs of a specific sector. 
                  Cogent Health builds EMR and ERP solutions for hospitals, while Diyalo 
                  Technologies focuses on ticketing systems and utility services. 
                  Foneinsure provides core systems tailored for the insurance sector, 
                  while Esewa Money Transfer, aims to digitize the 
                  remittance industry.
                  <br />
                  <br />
                  {/* We are also committed to the continuous improvement of our
                  products and services to deliver maximum value to our clients
                  and end users. The following verticals ensure that the
                  required analytics, research and resources are constantly
                  supplied to the ecosystem. Logica Beans is our off-shoring and
                  R&D unit. Working with international clients and partners,
                  Logica introduces the group to the latest technological trends
                  and practices. Swastik College offers tailor-made
                  curriculum to its students to provide us with a talent pool
                  ready to fit into our verticals. */}
                  We are also committed to the continuous improvement of our 
                  products and services to deliver maximum value to our clients 
                  and end users. The following verticals ensure that the required 
                  analytics, research, and resources are constantly supplied to 
                  the ecosystem. Logica Beans is our off-shoring and R&D unit. 
                  Working with international clients and partners, Logica 
                  introduces the group to the latest technological trends and 
                  practices. Swastik College offers a tailor-made curriculum 
                  to its students to provide us with a talent pool ready to fit 
                  into our verticals.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ---------------investment arm for mobile view  ------------------*/}

        <div className="investment-arm-mb  d-md-none ">
          <Container>
            <Row>
              <Col md="6">
                <h5>Investment Arm</h5>
                <p className="text-muted">
                  One of the main challenges to the adoption of digital finance
                  in Nepal is the lack of digitization across various
                  industries/sectors and hence the inability to process digital
                  transactions. For instance, it is not possible to purchase bus
                  tickets digitally until the ticketing system itself is
                  digitized.
                  <br />
                  <br /> Realizing this gap, the F1Soft Group has invested in
                  various tech companies each of which caters to the digital
                  needs of a specific sector. Cogent Health builds EMR and ERP
                  solutions for hospitals while Diyalo technologies focuses on
                  ticketing systems and utility services. IMS software
                  specializes in developing billing software solutions for the
                  retail and hospitality industry. Foneinsure provides core
                  systems tailored for the insurance sector while eSewa Money
                  Transfer, our latest venture, looks to digitize the remittance
                  industry.
                  <br />
                  <br />
                  We are also committed to the continuous improvement of our
                  products and services to deliver maximum value to our clients
                  and end users. The following verticals ensure that the
                  required analytics, research and resources are constantly
                  supplied to the ecosystem. Logica Beans is our off-shoring and
                  R&D unit. Working with international clients and partners,
                  Logica introduces the group to the latest technological trends
                  and practices. Shiran Technologies develops in-house web and
                  mobile applications while Swastik College offers tailor-made
                  curriculum to its students to provide us with a talent pool
                  ready to fit into our verticals.
                </p>
              </Col>
              <Col md="6">
                <div className="investment-logos">
                  <Row>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-01.png" alt="" />
                    </div>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-02.png" alt="" />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-03.png" alt="" />
                    </div>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-04.png" alt="" />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-05.png" alt="" />
                    </div>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-06.png" alt="" />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-07.png" alt="" />
                    </div>
                    <div className="col-6">
                      <img src="/img/about/ecosystem/logo-08.png" alt="" />
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  )
}
export default Company
