import React from 'react'
// import Page from 'react-page-loading'
export const OtherPageLoader = () => {
  const pageLoader = {
    position: 'absolute',
    left: 0,
    top: '200px'
  }
  return (
    <div
      className="page-loader"
      style={{ ...pageLoader, ...{ position: 'relative' } }}
    >
      {/* <Page loader={'rotate-spin'} color={'#b22222'} size={3} duration={5} /> */}
    </div>
  )
}

function PageLoader() {
  const pageLoader = {
    position: 'absolute',
    left: 0,
    top: '-49px'
  }
  return (
    <div
      className="page-loader"
      style={{ ...pageLoader, ...{ position: 'relative' } }}
    >
      {/* <Page loader={'rotate-spin'} color={'#b22222'} size={3} duration={5} /> */}
    </div>
  )
}
export default PageLoader
