import React from 'react'
import { BannerImg, BannerMenu } from '../components/BannerNav'
import { StickyContainer, Sticky } from 'react-sticky'

export default function StickyLayout({ bannerItem, bannerNav, children }) {
  return (
    <>
      {bannerItem && (
        <BannerImg
          // isLoading={isLoading}
          // className={className}
          bannerItem={bannerItem}
        />
      )}
      <StickyContainer>
        <Sticky>
          {({ style }) => (
            //   <Header style={style} renderCount={renderCount++} />
            <div className="sticky-menu" style={style}>
              <BannerMenu bannerNav={bannerNav} />
            </div>
          )}
        </Sticky>

        {children}
      </StickyContainer>
    </>
  )
}
