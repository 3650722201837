import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import CorporateNewsDetails from '../../components/media/CorporateNewsDetails'
import Breadcrumb from '../../containers/navs/BreadCrumbs'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { CorporateNewsDetails } from '../../../data/media/corporatenews/cooperatenews-detail';
// import { corporateNews } from '../../../data/media/corporatenews';
import { scrollToTop } from '../../utils/scrollToTop'
import { useParams } from 'react-router-dom'
import { ApiNames, fetchData } from '../../remote-data/fetchData'
import { useQuery } from 'react-query'
import PageLoader from '../../utils/pageloader'

function NewsDetails(props) {
  const { id } = useParams()
  // console.log('id', id);
  //API: GET
  const {
    status,
    data: corporateNewsDetails,
    isLoading: newsdetailLoading
  } = useQuery(ApiNames.CORPORATE_NEWS_DETAIL_BY_ID, () =>
    fetchData(ApiNames.CORPORATE_NEWS_DETAIL_BY_ID, {
      // pathValues: [id] // /corporate-news/view/1
      params: { id: id } // /corporate-news/view?id=1
    })
  )

  // console.log(corporateNewsDetails);

  useEffect(() => {
    // Scroll to top of the page on route change
    scrollToTop()
    document.body.className = 'page-news-details'
    return () => {
      document.body.className = ''
    }
  })

  return (
    <div className="news-details">
      <Breadcrumb />
      {(status === 'loading' || newsdetailLoading) && <PageLoader />}

      <Container>
        {corporateNewsDetails && (
          <CorporateNewsDetails
            newsdetailItem={corporateNewsDetails.data}
            isLoading={newsdetailLoading}
          />
        )}
      </Container>
    </div>
  )
}

export default NewsDetails
