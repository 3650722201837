import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import Corporatenews from './Corporate-News.js'
import { Link } from 'react-router-dom'
import { ApiNames, fetchData } from '../../remote-data/fetchData'
import { useQuery } from 'react-query'
import CorporateNewsLoader from '../../utils/shimmer'

function Corporate() {
  //API: GET
  const {
    status,
    data: corporateNews,
    isLoading: corporateNewsLoading
  } = useQuery(
    ApiNames.CORPORATE_NEWS,
    () =>
      fetchData(ApiNames.CORPORATE_NEWS, {
        params: { size: 4 }
      })
    // corporate-news/index?sort=-created
  )

  return (
    <div className="corporate">
      <Container>
        <Row>
          <Col md="6">
            <div className='shadow'>
                <a href="https://connect2.amtivo.com/cert/amtivocert10001.asp?c=621840&v=VR114658BHQY&e=61826" target="_blank" rel="noopener noreferrer">
                    <img className='img-fluid' src="/img/ISO-Certification.png" alt="ISO" />
                </a>
            </div>
            
          </Col>

          <Col md="6">
            <div className="corporate-news-section">
              <h5>Corporate News</h5>

              {(status === 'loading' || corporateNewsLoading) && (
                <div>
                  <CorporateNewsLoader />
                  <CorporateNewsLoader />
                  <CorporateNewsLoader />
                </div>
              )}
              {corporateNews &&
                corporateNews.data &&
                corporateNews.data.map(newsItem => (
                  <Link to={`/media/corporatenews/${newsItem.id}`}>
                    <Corporatenews
                      newsItem={newsItem}
                      isLoading={corporateNewsLoading}
                    />
                  </Link>
                ))}
              <Link to="/media/corporatenews">
                <Button outline color="danger">
                  Read all news
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Corporate
