import React, { Fragment, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Corporatenew from '../../components/media/Corporatenew'
import { ApiNames, fetchData } from '../../remote-data/fetchData'
import { useQuery } from 'react-query'
import { MediaCorporatenewsLoader } from '../../utils/shimmer'
import StickyLayout from '../../layout/StickyLayout'
import { mediaBannerItem, mediaBannerNav } from './BannerMedia'
import Pagination from '../../containers/pages/Pagination'
function CorporateNews(props) {
  const [currentPage, setCurrentPage] = useState(1) //page: 1

  //API: GET
  const {
    status,
    data: corporateNews,
    isLoading: mediacorporatenewsLoading
  } = useQuery([ApiNames.CORPORATE_NEWS, { currentPage }], () =>
    fetchData(
      ApiNames.CORPORATE_NEWS,
      {
        params: {
          page: currentPage
        }
      },
      {},
      true
    )
  )
  const corporatenewsList = corporateNews?.data?.data
  const corporatenewsHeaders = corporateNews?.headers
  const totalPage =
    corporatenewsHeaders && corporatenewsHeaders['x-pagination-page-count']

  return (
    <StickyLayout bannerItem={mediaBannerItem} bannerNav={mediaBannerNav}>
      <Fragment>
        <div className="corporate-news">
          <Container>
            <div
            //  style={{ paddingTop: '90px' }}
            >
              {(status === 'loading' || mediacorporatenewsLoading) && (
                <Row>
                  <Col sm="6" lg="4" md="4">
                    <MediaCorporatenewsLoader />
                  </Col>
                  <Col sm="6" lg="4" md="4">
                    <MediaCorporatenewsLoader />
                  </Col>
                  <Col sm="6" lg="4" md="4">
                    <MediaCorporatenewsLoader />
                  </Col>
                </Row>
              )}
            </div>

            <Row>
              {/* {console.log('cN', corporateNews)} */}

              {corporatenewsList &&
                corporatenewsList.map(newsItem => (
                  <Col md="4">
                    <Corporatenew
                      newsItem={newsItem}
                      isLoading={mediacorporatenewsLoading}
                    />
                  </Col>
                ))}
            </Row>
            <Row className="d-flex justify-content-center">
              {totalPage && (
                <Pagination
                  currentPage={currentPage}
                  // totalPage={Math.ceil(pagination.totalCount / pagination.size)}
                  totalPage={totalPage}
                  numberLimit={5}
                  onChangePage={i => {
                    // console.log(i)
                    setCurrentPage(i) // - 1
                  }}
                />
              )}
            </Row>
          </Container>
        </div>
      </Fragment>
    </StickyLayout>
  )
}
export default CorporateNews
