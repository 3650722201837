import React, { useState } from 'react'
import { yearIndex, yearPoints, yearPointsSmall } from './yearData'
import './_circular-timeline.scss'

// const brandColor = '#ffffff'; //cf252d
const brandColor = 'rgb(178, 34, 34)'
const grayColor = 'rgb(51, 51, 51)'
// const fontSize = '18px';

const defaultTimelineStyle = {
  transsition: 200 + 'ms',
  transitionTimingFnction: 'ease-out',
  transitionDelay: 0,
  position: 'absolute',
  stroke: brandColor,
  fill: 'rgb(255, 255, 255)',
  opacity: 1
}

const CircularTimeline = ({ bgImages, yearText }) => {
  // active year for changing bgImages, color
  const [activeYearIndex, setActiveYearIndex] = useState(
    yearIndex.indexOf(2023)
  )

  // if year === 2018, fill: brandColor else grayColor
  const getActiveYearColor = year =>
    activeYearIndex === yearIndex.indexOf(year) ? brandColor : grayColor

  return (
    <>
      <div className="svg-component-wrapper">
        <div id="Component">
          <div className="svg-wrapper">
            <ImgWrapper
              className="img-wrapper-large"
              width={420}
              activeYearIndex={activeYearIndex}
              bgImages={bgImages}
            />

            <ImgWrapper
              className="img-wrapper-small"
              width={200}
              activeYearIndex={activeYearIndex}
              bgImages={bgImages}
            />

            <SvgCircle
              // yearPoints={yearPoints}
              activeYearIndex={activeYearIndex}
              setActiveYearIndex={setActiveYearIndex}
              // yearIndex={yearIndex}
              getActiveYearColor={getActiveYearColor}
            />

            <SvgCircleSmall
              // yearPoints={yearPoints}
              activeYearIndex={activeYearIndex}
              setActiveYearIndex={setActiveYearIndex}
              // yearIndex={yearIndex}
              getActiveYearColor={getActiveYearColor}
            />
          </div>
        </div>
      </div>

      <TextWrapper
        activeYearIndex={activeYearIndex}
        yearText={yearText}
        year={yearIndex[activeYearIndex]}
      />
    </>
  )
}

const SvgCircle = React.memo((
  {
    // yearPoints,
    activeYearIndex,
    setActiveYearIndex,
    getActiveYearColor
  } // yearIndex,
) => (
  <svg className="svg-circle-large" width="100%" height="100%" style={{}}>
    <circle
      cx="50%"
      cy="50%"
      r="260"
      fill="transparent"
      stroke="#333"
      strokeWidth="1"
      strokeDasharray="1305.2211349000193 1633.6281798666923"
      style={{
        transformOrigin: ' center center',
        transform: 'rotate(207deg)',
        opacity: '1'
      }}
    ></circle>

    <circle
      cx="50%"
      cy="50%"
      r="260"
      fill="transparent"
      // stroke={brandColor}
      strokeWidth="2"
      strokeDasharray="0 1633.6281798666923"
      style={{
        transformOrigin: 'center',
        transform: 'rotate(225deg)'
      }}
    ></circle>

    <g>
      {yearPoints.map((yp, i) => (
        <g
          key={i}
          // zid="0"
          style={{
            cursor: 'pointer',
            transformOrigin: yp.transformOrigin,
            opacity: 1
          }}
          onClick={() => {
            setActiveYearIndex(yearIndex.indexOf(yp.year))
          }}
        >
          <g>
            {/* Normal State */}
            <NormalCircle
              cx={yp.cx}
              cy={yp.cy}
              // fill={getActiveYearColor(2018)}
              year={yp.year}
              activeYearIndex={activeYearIndex}
              // yearIndex={yearIndex}
            />

            <NormalText
              x={yp.x}
              y={yp.y}
              fill={getActiveYearColor(yp.year)}
              text={yp.year}
            />
          </g>
        </g>
      ))}
    </g>
  </svg>
))

const SvgCircleSmall = React.memo(
  ({ setActiveYearIndex, activeYearIndex, getActiveYearColor }) => (
    <svg className="svg-circle-small" width="100%" height="100%">
      <circle
        cx="50%"
        cy="50%"
        r="115.19999999999999"
        fill="transparent"
        stroke="#333"
        stroke-width="1"
        stroke-dasharray="585.8672105403161 723.8229473870882"
        style={{
          transformOrigin: 'center center',
          transform: 'rotate(113deg)',
          opacity: 1
        }}
      ></circle>
      {/* <circle
        cx="50%"
        cy="50%"
        r="115.19999999999999"
        fill="transparent"
        stroke="#0196E4"
        stroke-width="2"
        stroke-dasharray="38.7762293243083 723.8229473870882"
        style={{ transformOrigin: 'center', transform: 'rotate(135deg)' }}
      ></circle> */}

      <g>
        {yearPointsSmall.map((yp, i) => (
          <g
            key={i}
            // zid="0"
            style={{
              cursor: 'pointer',
              transformOrigin: yp.transformOrigin,
              opacity: 1
            }}
            onClick={() => {
              setActiveYearIndex(yearIndex.indexOf(yp.year))
            }}
          >
            <g>
              {/* Normal State */}
              <NormalCircle
                cx={yp.cx}
                cy={yp.cy}
                // fill={getActiveYearColor(2018)}
                year={yp.year}
                activeYearIndex={activeYearIndex}
                // yearIndex={yearIndex}
              />

              <NormalText
                x={yp.x}
                y={yp.y}
                fill={getActiveYearColor(yp.year)}
                text={yp.year}
              />
            </g>
          </g>
        ))}
      </g>
    </svg>
  )
)

const ImgWrapper = React.memo(
  ({ className, width, activeYearIndex, bgImages }) => {
    const numberOfYear = 16
    return (
      <div
        className={`img-wrapper ${className}`}
        style={{
          // width: '420px',
          // height: '420px',
          // top: '115px',
          // left: '115px',
          // borderRadius: '420px',
          transform: 'matrix(1, 0, 0, 1, 0, 0)'
        }}
      >
        <ul
          style={{
            width: width * numberOfYear,
            transform: `matrix(1, 0, 0, 1, ${-activeYearIndex * width}, 0)`
          }}
        >
          {bgImages.map((img, i) => (
            <li
              key={i}
              style={{
                backgroundImage: `url(${img})`,
                // width: '420px',
                // height: '420px',
                left: '0px',
                opacity: '1'
              }}
            ></li>
          ))}
        </ul>
      </div>
    )
  }
)

const TextWrapper = React.memo(({ activeYearIndex, yearText, year }) => {
  return (
    <div className="text-wrapper at-first at-last">
      <div className="text-content full">
        <h2 style={{ color: brandColor }}>{year}</h2>
        <ul
          className="fn-clear list-style-disc"
          style={{
            fontSize: 16,
            // width: '280px',
            transform: 'matrix(1, 0, 0, 1, 0, 0)'
          }}
        >
          <li>
            <div className="text">{yearText[activeYearIndex]}</div>
          </li>
        </ul>
      </div>
    </div>
  )
})

// const ActiveCircle = ({ cx, cy }) => (
// );

const NormalCircle = ({ cx, cy, activeYearIndex, year }) => (
  <>
    {activeYearIndex === yearIndex.indexOf(year) ? (
      <>
        <circle
          // zid="0"
          cx={cx}
          cy={cy}
          r="4"
          style={{
            ...defaultTimelineStyle
            // ...defaultTimeLineTransition[state],
            // ...{ position: 'relative' },
          }}
        ></circle>
        <circle
          zid="0"
          cx={cx}
          cy={cy}
          r="15"
          style={{
            opacity: '0.2',
            fill: 'transparent',
            stroke: brandColor
          }}
        >
          <animate
            attributeName="r"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
            // from="8"
            // to="15"
            values="12;15;12"
          />
        </circle>
      </>
    ) : (
      <circle
        // zid="0"
        cx={cx}
        cy={cy}
        r="2"
        style={{
          stroke: 'transparent',
          // fill: fill,
          fill: grayColor
        }}
      ></circle>
    )}
  </>
)

const NormalText = ({ x, y, fill, text }) => (
  <text
    // zid="0"
    x={x}
    y={y}
    className="year-text"
    style={{
      // fontSize: fontSize,
      // fontWeight: 600,
      fill: fill
    }}
  >
    {text}
  </text>
)

// const YearPoint = ({
//   activeYearIndex,
//   setActiveYearIndex,
//   year,
//   transformOrigin,
//   cx,
//   cy,
//   x,
//   y,
// }) => {
//   return (
//   );
// };

export default CircularTimeline
