import React, { useState, useCallback } from 'react'
import ReactAwesomePlayer from 'react-awesome-player'
import './_video.scss'
import './_custom-video.scss'

const captions = [
  // <div className="caption-item">
  //   TRANSFORMING NEPAL <span>through digital finance</span>
  // </div>,
  <div className="caption-item">
    SIMPLIFYING FINANCIAL ACCESS <span>for economic prosperity</span>
  </div>,
  <div className="caption-item">
    DIGITIZING INDUSTRIES <span>for improved efficiency</span>
  </div>,
  <div className="caption-item">
    REVOLUTIONIZING PAYMENTS <span>for enhanced transparency</span>
  </div>,
  <div className="caption-item">
    CREATING OPPORTUNITIES <span>for earning livelihoods</span>
  </div>,
  // <div className="caption-item">
  //   CRAFTING SOLUTIONS <span>for a digital Nepal</span>
  // </div>
]

// class Html5Video extends React.Component {
function Html5Video() {
  const state = React.useMemo(
    () => ({
      options: {
        autoplay: 'muted', // 'muted', true
        controls: true, // false
        loop: true,
        poster: '/videos/screenshot.jpg',
        sources: [
          {
            type: 'video/mp4',
            src: 'https://api.f1soft.com/images/banner.mp4'
          },
          {
            type: 'video/webm',
            src: 'https://api.f1soft.com/images/banner.webm'
          }
        ]
        // subtitles: [
        //   {
        //     language: 'en',
        //     url: '/videos/en.vtt',
        //     label: 'EN',
        //   },
        // ],
        // defaultSubtitle: 'en',
      }
    }),
    []
  )
  //   loadeddata() {
  //     console.log("loadeddata");
  //   }
  //   canplay() {
  //     console.log("canplay");
  //   }
  //   canplaythrough() {
  //     console.log("canplaythrough");
  //   }
  //   play() {
  //     console.log("play");
  //   }
  //   pause() {
  //     console.log("pause");
  //   }
  //   waiting() {
  //     console.log("waiting");
  //   }
  //   playing() {
  //     console.log("playing");
  //   }
  //   ended() {
  //     console.log("ended");
  //   }
  //   error() {
  //     console.log("error");
  //   }

  // render() {

  const [captionIndex, setCaptionIndex] = useState(null)

  const handleTimeUpdate = useCallback(
    event => {
      // console.log(event, 'time');

      if (event < 3.401) {
        setCaptionIndex(0)
      } else if (event < 6.83) {
        setCaptionIndex(1)
      } else if (event < 10.2) {
        setCaptionIndex(2)
      } else if (event < 13.46) {
        setCaptionIndex(3)
      } else if (event < 16.96) {
        setCaptionIndex(4)
      } else if (event < 20.63) {
        setCaptionIndex(5)
      }
    },
    [setCaptionIndex]
  )

  const { options } = state
  return (
    <div className="video-wrapper">
      {captionIndex !== null && (
        <div className="video-caption-overlay">
          <div className="video-caption">{captions[captionIndex]}</div>
        </div>
      )}
      <ReactAwesomePlayer
        options={options}
        // loadeddata={this.loadeddata}
        // canplay={this.canplay}
        // canplaythrough={this.canplaythrough}
        // play={this.play}
        // pause={this.pause}
        // waiting={this.waiting}
        // playing={this.playing}
        // ended={this.ended}
        // error={this.error}
        timeupdate={handleTimeUpdate}
      />
    </div>
  )
}

// }

export default Html5Video
