import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { scrollToTop } from '../../utils/scrollToTop'

function PaymentProcessing(props) {
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="paymentprocessing">
        <Container>
          <Row>
            <Col md="12">
              <div className="esewa-header clearfix">
                <div className=" logo">
                  <img src="/img/logos/logo-15.png" alt="" />
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                    FoneNXT, provides a complete end-to-end Banking-as-a-Product 
                    platform for the next generation. It is a plug-and-play 
                    solution for banking to set up a virtual bank for any digital, 
                    legacy, or other types of banks.
                    <br />
                    <br />
                    FoneNxt includes several banking products and services, including:
                    <br />
                    <br />
                    <ul>
                      <li>Customer Onboarding</li>
                      <li>Customer Verification</li>
                      <li>Document Management</li>
                      <li>Payments, and more</li>
                    </ul>
                    <br />
                    FoneNXT strives to create a virtual bank so that customers need 
                    not physically visit a branch for any reason.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default PaymentProcessing
