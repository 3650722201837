import React, { Fragment, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Newsletter from '../../components/media/Newsletter'
import imageUrl, { ApiNames, fetchData } from '../../remote-data/fetchData'
import { useQuery } from 'react-query'
import { NewsLetterLoader } from '../../utils/shimmer'
import StickyLayout from '../../layout/StickyLayout'
import { mediaBannerItem, mediaBannerNav } from './BannerMedia'
import Pagination from '../../containers/pages/Pagination'

function CorporateNews(props) {
  const [currentPage, setCurrentPage] = useState(1) //page: 1

  // API :GET
  const { status, data: newsLetter, isLoading: newsletterLoading } = useQuery(
    [ApiNames.NEWS_LETTER, { currentPage }],
    () =>
      fetchData(
        ApiNames.NEWS_LETTER,
        {
          params: {
            page: currentPage
          }
        },
        {},
        true
      )
  )

  const newsList = newsLetter?.data?.data
  const newsHeaders = newsLetter?.headers
  const totalPage = newsHeaders && newsHeaders['x-pagination-page-count']

  return (
    <StickyLayout bannerItem={mediaBannerItem} bannerNav={mediaBannerNav}>
      <Fragment>
        <div className="news-letter">
          <Container>
            {(status === 'loading' || newsletterLoading) && (
              <Row>
                <Col sm="6" lg="4" md="4">
                  <NewsLetterLoader />
                </Col>
                <Col sm="6" lg="4" md="4">
                  <NewsLetterLoader />
                </Col>
                <Col sm="6" lg="4" md="4">
                  <NewsLetterLoader />
                </Col>
              </Row>
            )}

            <Row>
              {newsList &&
                newsList !== null &&
                newsList.map(newsletterItem => (
                  <Col md="4">
                    <Newsletter
                      newsletterItem={newsletterItem}
                      isLoading={newsletterLoading}
                      imageUrl={imageUrl}
                    />
                  </Col>
                ))}
            </Row>
            <Row className="d-flex justify-content-center">
              {totalPage && (
                <Pagination
                  currentPage={currentPage}
                  totalPage={totalPage}
                  numberLimit={5}
                  onChangePage={i => {
                    setCurrentPage(i)
                  }}
                />
              )}
            </Row>
          </Container>
        </div>
      </Fragment>
    </StickyLayout>
  )
}
export default CorporateNews
