/* 
  * year index for setActiveYearIndex(yearIndex[2018])
  
  * If you want to add new, new i.e 2019
  - Add it at the beginning
  - Also add it's image in bgImages 
  - and content in yearText
  - and remove the last items

  * There should be only 15 year item
*/
export const yearIndex = [
  2023,
  2022,
  2020,
  2019,
  2018,
  2017,
  2016,
  // 2015,
  2014,
  2013,
  2012,
  2010,
  2009,
  2008,
  2007,
  2006,
  2004
]

export const yearPoints = [
  {
    year: yearIndex[0],
    transformOrigin: '92.552 208.152px',
    cx: 92.5522368914976,
    cy: 208.15223689149767,
    x: 32.6062953454792,
    y: 195.60629534547928
  },
  {
    year: yearIndex[1],
    transformOrigin: '141.152px 141.152px',
    cx: 141.1522368914976,
    cy: 141.15223689149767,
    x: 94.6062953454792,
    y: 125.60629534547928
  },
  {
    year: yearIndex[2],
    transformOrigin: '212.19px 90.7481px',
    cx: 212.19022782943483,
    cy: 90.74809434537104,
    x: 169.3327973513478,
    y: 72.58501462868838
  },
  {
    year: yearIndex[3],
    transformOrigin: '295.889px 66.6348px',
    cx: 295.8892362131399,
    cy: 66.63482542775694,
    x: 257.37771578574524,
    y: 47.21971059419815
  },
  {
    year: yearIndex[4],
    transformOrigin: '382.855px 71.5187px',
    cx: 382.8554428286417,
    cy: 71.51874283272585,
    x: 385.85947543705197,
    y: 52.35721601827123
  },
  {
    year: yearIndex[5],
    transformOrigin: '463.328px 104.852px',
    cx: 463.3283398939873,
    cy: 104.85170820064596,
    x: 470.5107729269443,
    y: 87.42093151106411
  },
  {
    year: yearIndex[6],
    transformOrigin: '528.276px 162.893px',
    cx: 528.2761854416877,
    cy: 162.89265151672925,
    x: 538.8309104550061,
    y: 148.47553919163633
  },
  {
    year: yearIndex[7],
    transformOrigin: '570.41px 239.127px',
    cx: 570.4096658801756,
    cy: 239.1274438916565,
    x: 583.1520908393385,
    y: 228.66867655526175
  },
  {
    year: yearIndex[8],
    transformOrigin: '585px 325px',
    cx: 585,
    cy: 324.99999999999994,
    x: 598.5,
    y: 330.99999999999994
  },
  {
    year: yearIndex[9],
    transformOrigin: '570.41px 410.873px',
    cx: 570.4096658801756,
    cy: 410.87255610834336,
    x: 583.1520908393386,
    y: 437.33132344473813
  },
  {
    year: yearIndex[10],
    transformOrigin: '528.276px 487.107px',
    cx: 528.2761854416877,
    cy: 487.1073484832707,
    x: 538.8309104550062,
    y: 517.5244608083635
  },
  {
    year: yearIndex[11],
    transformOrigin: '463.328px 545.148px',
    cx: 463.32833989398756,
    cy: 545.1482917993538,
    x: 470.5107729269446,
    y: 578.5790684889357
  },
  {
    year: yearIndex[12],
    transformOrigin: '382.855px 578.481px',
    cx: 382.8554428286418,
    cy: 578.4812571672742,
    x: 385.8594754370521,
    y: 613.6427839817287
  },
  {
    year: yearIndex[13],
    transformOrigin: '295.889px 583.365px',
    cx: 295.8892362131398,
    cy: 583.3651745722431,
    x: 257.3777157857451,
    y: 618.7802894058018
  },
  {
    year: yearIndex[14],
    transformOrigin: '212.19px 559.252px',
    cx: 212.19022782943495,
    cy: 559.251905654629,
    x: 169.33279735134795,
    y: 593.4149853713117
  },
  {
    year: yearIndex[15],
    transformOrigin: '141.152px 508.848px',
    cx: 141.15223689149755,
    cy: 508.84776310850225,
    x: 94.60629534547914,
    y: 540.3937046545207
  }
]

export const yearPointsSmall = [
  {
    year: yearIndex[0],
    transformOrigin: '90.5413px 275.459px',
    cx: 113.54129880730974,
    cy: 265.45870119269028,
    x: 90.995357261291346,
    y: 295.00464273870864
  },
  {
    year: yearIndex[1],
    transformOrigin: '78.5413px 241.459px',
    cx: 78.54129880730974,
    cy: 241.45870119269028,
    x: 41.995357261291346,
    y: 273.00464273870864
  },
  {
    year: yearIndex[2],
    transformOrigin: '56.2084px 209.983px',
    cx: 56.208386417641336,
    cy: 209.9834067463427,
    x: 17.045306700958676,
    y: 237.84083722442975
  },
  {
    year: yearIndex[3],
    transformOrigin: '45.5244px 172.898px',
    cx: 45.52435342029847,
    cy: 172.8983076471011,
    x: 5.109238586739693,
    y: 196.40982807449575
  },
  {
    year: yearIndex[4],
    transformOrigin: '47.6883px 134.366px',
    cx: 47.68830451665393,
    cy: 134.3655884082326,
    x: 7.526777702199311,
    y: 125.36155579982236
  },
  {
    year: yearIndex[5],
    transformOrigin: '62.4574px 98.7099px',
    cx: 62.45737224890166,
    cy: 98.70990478543325,
    x: 24.02659555931983,
    y: 85.5274717524762
  },
  {
    year: yearIndex[6],
    transformOrigin: '88.174px 69.933px',
    cx: 88.17397482587388,
    cy: 69.93301321968299,
    x: 52.75686250078098,
    y: 53.37828820636459
  },
  {
    year: yearIndex[7],
    transformOrigin: '121.952px 51.2646px',
    cx: 121.95185206276474,
    cy: 51.26464034847608,
    x: 90.49308472636997,
    y: 32.52221538931312
  },
  {
    year: yearIndex[8],
    transformOrigin: '160px 44.8px;',
    cx: 159.99999999999997,
    cy: 44.80000000000001,
    x: 146.49999999999997,
    y: 25.30000000000001
  },
  {
    year: yearIndex[9],
    transformOrigin: '198.048px 51.2646px',
    cx: 198.04814793723523,
    cy: 51.264640348476064,
    x: 202.50691527362997,
    y: 32.522215389313104
  },
  {
    year: yearIndex[10],
    transformOrigin: '231.826px 69.933px',
    cx: 231.82602517412607,
    cy: 69.93301321968296,
    x: 240.24313749921896,
    y: 53.37828820636456
  },
  {
    year: yearIndex[11],
    transformOrigin: '257.543px 98.7099px',
    cx: 257.5426277510983,
    cy: 98.70990478543321,
    x: 268.97340444068016,
    y: 85.52747175247616
  },
  {
    year: yearIndex[12],
    transformOrigin: '272.312px 134.366px',
    cx: 272.31169548334606,
    cy: 134.36558840823255,
    x: 285.4732222978007,
    y: 125.3615557998223
  },
  {
    year: yearIndex[13],
    transformOrigin: '274.476px 172.898px',
    cx: 274.47564657970156,
    cy: 172.89830764710103,
    x: 287.8907614132603,
    y: 196.4098280744957
  },
  {
    year: yearIndex[14],
    transformOrigin: '263.792px 209.983px',
    cx: 263.7916135823587,
    cy: 209.98340674634255,
    x: 275.9546932990414,
    y: 237.8408372244296
  },
  {
    year: yearIndex[15],
    transformOrigin: '241.459px 241.459px',
    cx: 241.45870119269028,
    cy: 241.45870119269023,
    x: 251.0046427387087,
    y: 273.00464273870864
  }
]
