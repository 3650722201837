import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function DigitalWallet(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="digitalwallet">
        <Container>
          <Row>
            <Col md="7">
              <div className="esewa-header clearfix">
                <div className=" logo ">
                  <img src="/img/logos/logo-02.png" alt="" />
                </div>
                <div className="media">
                  <ul className="follow-us">
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/esewa"
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </a>
                    </li>
                    <li className="twitter">
                      <a
                        href="https://twitter.com/eSewaNepal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/esewa_official/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                      </a>
                    </li>
                    <li className="youtube">
                      <a
                        href=" https://www.youtube.com/channel/UCr5isSl8_yDp0qkVswX-6tQ/"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={['fab', 'youtube']} />
                      </a>
                    </li>
                    <li className="website">
                      <a
                        href="https://esewa.com.np/#/home"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                        <img src="/img/www.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                  {/* eSewa was established in 2009 and is licensed by Nepal Rastra
                  Bank as a Payment Service Provider. eSewa is Nepal’s first and
                  leading online payment gateway providing comprehensive payment
                  solutions. With eSewa digital wallet, customers can pay, send
                  and receive money simply using the mobile app or a web
                  browser. The digital wallet is a one-stop solution for online
                  payment of utilities, internet, movies, travel tickets, online
                  shopping, food and hospitality, credit cards, insurance, EMI’s
                  and so on. */}
                  eSewa was established in 2009 and is licensed by the Nepal Rastra 
                  Bank as a Payment Service Provider. eSewa is Nepal’s first and leading 
                  online payment gateway, providing comprehensive payment solutions. 
                  With the eSewa digital wallet, customers can pay, send, and receive 
                  money simply using the mobile app or a web browser. The digital wallet 
                  is a one-stop solution for online payment of utilities, internet 
                  services, movies, travel tickets, online shopping, food and hospitality, 
                  credit cards, insurance, EMI’s, and more.
                  <br />
                  <br />
                  {/* eSewa received GSMA innovation fund, UK Department for
                  International Development (DFID), in 2017. The company
                  facilitates financial access through 200K+ agent networks and
                  serves over 7.8 million people across Nepal. The agent led model
                  adopted by eSewa has not only given employment opportunities
                  to hundreds of people but also supported many retail and
                  wholesale businesses and emerging entrepreneurs in Nepal. With
                  introducing a brand anthem to sponsoring popular national
                  reality shows, eSewa has been successful in being a household
                  brand name, a pioneer in the digital payment domain and
                  F1Soft’s most prominent brand. */}
                  eSewa received the GSMA Innovation Fund from the UK Department for 
                  International Development (DFID) in 2017. The company facilitates 
                  financial access through a network of 200k+ agents and serves over 
                  7.8 million people across Nepal. The agent-led model adopted by eSewa 
                  has not only provided employment opportunities to hundreds of people 
                  but also supported many retail and wholesale businesses and emerging 
                  entrepreneurs in Nepal. By introducing a brand anthem and sponsoring 
                  popular national reality shows, eSewa has successfully become a household 
                  brand name, a pioneer in the digital payment domain, and F1Soft’s most 
                  prominent brand.
                  <br />
                </p>
                <table>
                  <tr>
                    <th>Active Users</th>
                    <th>Partner Banks</th>
                    <th>Merchants</th>
                    <th>Agents</th>
                  </tr>
                  <tr>
                    <td>7.8M</td>
                    <td>49</td>
                    <td>400K+</td>
                    <td>200K+</td>
                  </tr>
                </table>
                <div className="app">
                  <ul>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.f1soft.esewa&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/anroid.svg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/np/app/esewa/id614370939"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/iso.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md="5">
              <img
                className="img-collection img-responsive"
                src="/img/business/digital-wallet/esewa_web.jpg"
                alt="prop"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default DigitalWallet
