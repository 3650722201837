import React from 'react';
// import './oursolution.scss';

function OurSolution() {
  return (
    <div className="our-solution">
      <p>
        Our solutions connect people to financial services in simple, secure and
        affordable ways.
      </p>
    </div>
  );
}

export default OurSolution;
