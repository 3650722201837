import React, { useEffect, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { scrollToTop } from '../../utils/scrollToTop'

function Esurance(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="esurance">
        <Container>
          <div className="esewa-header clearfix">
            <div className=" logo ">
              <img src="/img/logos/foneinsure.png" alt="" />
            </div>

            <div className=" media">
              <ul className=" follow-us">
                {/*<li className="facebook">
                  <a
                    href="https://www.facebook.com/extensodata"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
                 <li className="twitter">
                      <a href="https://twitter.com/f1soft">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li> 
                <li className="linkedin">
                  <a
                    href="https://www.linkedin.com/company/extensodata/"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </li>*/}
                {/* <li className="website">
                  <a
                    href="http://www.darshantech.com.np/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/img/www.svg" alt="" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="esewa-body">
            <p className="text-muted">
            Foneinsure was established in 2010 and is currently 
            working in the insurance domain. The company has developed a 
            complete suite for the insurance industry in Nepal. It serves the 
            country’s leading insurance companies by automating and digitizing 
            their workflows and payments. Foneinsure was acquired by F1Soft 
            Group in 2018.
            </p>
          </div>
          <div className="img-collection">
            <div className=" position-relative">
              <h5>Clients</h5>
              <div className="underline">
                {' '}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </div>
            </div>
            <Row>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/rjb.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/nlic.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/nplic.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/sjlc.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/hlic.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/rblic.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/srli.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/cli.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/snlic.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/pmli.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/sic.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/esurance/nbp.png" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
export default Esurance
