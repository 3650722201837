import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function Eticketing(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="eticketing">
        <Container>
          <div className="esewa-header clearfix">
            <div className=" logo">
              <img src="/img/logos/logo-01.png" alt="" />
            </div>

            <div className=" media">
              <ul className=" follow-us">
                <li className="facebook">
                  <a
                    href=" https://www.facebook.com/Diyalo-Technologies-2791771974196581"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
                {/* <li className="twitter">
                      <a href="https://twitter.com/f1soft">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li> */}
                <li className="linkedin">
                  <a
                    href="https://www.linkedin.com/company/diyalo-technologies-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </li>
                <li className="website">
                  <a
                    href="http://diyalotech.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                    <img src="/img/www.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="esewa-body">
            <p className="text-muted">
            Diyalo Technologies Pvt. Ltd. was acquired by the F1Soft group in 
            2018 to focus on building software solutions that help digitize 
            small businesses and communities. From simple accounting and inventory 
            applications to eTicketing platforms, Diyalo Technologies aims to 
            replace age-old manual processes with innovative software solutions 
            that save costs and time burdens, creating a significant impact on 
            society. Diyalo Technologies was established in 2012
            </p>
          </div>

          <div className="img-collection">
            <div className=" position-relative">
              <h5>Featured Products</h5>
              <div className="underline">
                {' '}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </div>
            </div>
            <Row>
              <Col md="3">
                <a
                  href="https://bussewa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/business/eticketing/bussewa.jpg" alt="" />
                </a>
              </Col>
              <Col md="3">
                <a
                  href="http://www.elekha.com.np/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/business/eticketing/2.jpg" alt="" />
                </a>
              </Col>
              <Col md="3">
                <div className="photo">
                  <img src="/img/business/eticketing/1.png" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
export default Eticketing
