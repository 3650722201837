export const mediaBannerItem = {
  image: '/img/bg_media.jpg',
  title: 'Media',
  caption: ' Latest updates, media coverage, newsletters & videos'
}

export const mediaBannerNav = [
  {
    name: 'Corporate News',
    url: '/media/corporatenews'
  },
  {
    name: 'Newsletter',
    url: '/media/newsletter'
  },
  {
    name: 'Videos',
    url: '/media/videos'
  },
  {
    name: 'Tenders',
    url: '/media/tenders'
  }
  // {
  //   name: 'Social Media',
  //   url: '/media/socialmedia'
  // }
]
