import React from 'react';
import Holder from '../svg/Holder';

function CustomImg(props) {
  const { url, width, height } = props;
  // console.log(typeof url);
  return (
    <figure
      className="background-cover background-center-center mb-0"
      style={{ backgroundImage: `url('${url}')` }}
    >
      {/* <img src={postItem.artwork} className="card-img-top" alt="" /> */}
      <Holder width={width} height={height} />
    </figure>
  );
}

export default CustomImg;
