import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import main from './views'
// import app from "./views/app";
// import user from "./views/user"
import error from './views/error'
import { login } from './remote-data/fetchData'

function App({ locale, account }) {
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    login().then(() => {
      setLoggedIn(true)
    })
  })

  return loggedIn ? (
    <div>
      <div className="h-100">
        <React.Fragment>
          <Router>
            <Switch>
              {/* <Route path="/app" component={app} /> */}
              <Route path="/error" exact component={error} />
              <Route path="/" component={main} />
              <Redirect to="/error" />
            </Switch>
          </Router>
        </React.Fragment>
      </div>
    </div>
  ) : null
}

export default App
