import React, { useEffect } from 'react'
import { Row, Col, Button } from 'reactstrap'
import AppLayout from '../layout/AppLayout'
import { Link } from 'react-router-dom'
import './error.scss'

function Error() {
  useEffect(() => {
    document.body.className = 'page-news-details'
    return () => {
      document.body.className = ''
    }
  })
  return (
    <AppLayout>
      <main className="error">
        <div className="container">
          <Row className="h-100">
            <Col xxs="12" md="10" className="mx-auto my-auto">
              <div className="error-page text-center mt-5">
                <div className="">
                  <p className="h2 font-weight-bold">SORRY, PAGE NOT FOUND!</p>
                  <p className="white">
                    The request page doesn’t exist. If you entered a web
                    address, please check it was correct. Please go to the
                    F1-Soft Homepage by clicking the button below.
                  </p>
                  <Link to="/">
                    <Button color="primary" outline className="btn-shadow">
                      F1-Soft Home
                    </Button>
                  </Link>
                </div>
                <div className="">
                  <img src="/img/error-bg.png" alt="" />
                  {/* <p className="display-1 font-weight-bold mb-5">404</p> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </main>
    </AppLayout>
  )
}
export default Error
