export const contactDetails = [
  {
    img: '/img/logos/logo-09.png',
    companyname: 'F1Soft International Pvt Ltd',
    companyaddress: 'F1Soft Group Tower, Pulchowk, Lalitpur',
    companyaddresssub: '',
    telephone: '5439131',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@f1soft.com',
    // website: ' www.f1soft.com',
    fb: 'https://www.facebook.com/f1soft',
    twitter: 'https://twitter.com/f1soft?lang=en',
    linkedin: 'https://www.linkedin.com/company/f1softgroup/',
    insta: '',
    youtube: 'https://www.youtube.com/user/F1softInternational',
    website: 'https://f1soft.com/'
  },
  {
    img: '/img/logos/logo-06.png',
    companyname: 'Fonepay Payment Service Ltd',
    companyaddress: '3rd floor, Alice Building, Pulchowk, Lalitpur',
    telephone: '',
    customersupp: '5970044',
    trollfree: '1660-01-34244',
    viber: '3424434244',
    email1: 'csd@fonepay.com',
    // website: 'www.fonepay.com'
    fb: 'https://www.facebook.com/fonepay',
    twitter: 'https://twitter.com/fonepay',
    linkedin: 'https://www.linkedin.com/company/fonepay/',
    insta: 'https://www.instagram.com/fonepaynepal/',
    youtube: 'https://www.youtube.com/channel/UCgVAQRwA0UKFUhXPK1xd2bA',
    website: 'https://www.fonepay.com/'
  },
  {
    img: '/img/logos/logo-02.png',
    companyname: 'eSewa Pvt Ltd',
    companyaddress: 'Pulchowk, Lalitpur',
    companyaddresssub: '',
    telephone: '',
    customersupp: '5970121',
    trollfree: '1660-01-02121',
    viber: ' +977 1234512345',
    whatsapp: '986923121',
    email1: 'csd@esewa.com.np',
    // website1: ' esewa.com.np',
    fb: 'https://www.facebook.com/esewa',
    twitter: 'https://twitter.com/eSewaNepal',
    linkedin: '',
    insta: 'https://www.instagram.com/esewa_official/',
    youtube: ' https://www.youtube.com/channel/UCr5isSl8_yDp0qkVswX-6tQ/',
    website: 'https://esewa.com.np/#/home'
  },
  {
    img: '/img/logos/logo-14.png',
    companyname: 'eSewa Money Transfer Pvt Ltd',
    companyaddress: 'F1Soft Group Tower, Pulchowk, Lalitpur',
    telephone: '5970377',
    customersupp: '',
    trollfree: '16600137777',
    viber: '',
    whatsapp: '',
    email1: 'info@esewaremit.com',
    // website: 'esewaremit.com'
    fb: 'https://www.facebook.com/esewamoneytransfer',
    twitter: '',
    linkedin: 'https://www.linkedin.com/company/esewa-money-transfer/',
    insta: 'https://www.instagram.com/esewa_moneytransfer/',
    youtube: '',
    website: 'https://esewamoneytransfer.com/'
  },
  {
    img: '/img/logos/foneloan.png',
    companyname: 'Foneloan Pvt Ltd',
    companyaddress: 'Pulchowk, Lalitpur',
    telephone: '',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@foneloan.com',
    fb: 'https://www.facebook.com/Foneloan/',
    twitter: '',
    linkedin: '',
    insta: 'https://www.instagram.com/foneloan/',
    youtube: '',
    website: 'https://foneloan.com.np/'
  },
  // {
  //   img: '/img/logos/fonenxt.png',
  //   companyname: 'FoneNXT Pvt Ltd',
  //   companyaddress: 'Pulchowk, Lalitpur',
  //   telephone: '',
  //   customersupp: '',
  //   trollfree: '',
  //   viber: '',
  //   whatsapp: '',
  //   email1: 'info@fonenxt.com',
  //   fb: '',
  //   twitter: '',
  //   linkedin: '',
  //   insta: '',
  //   youtube: '',
  //   website: ''
  // },
  {
    img: '/img/logos/fonepoints.png',
    companyname: 'FonePoints Pvt Ltd',
    companyaddress: 'Pulchowk, Lalitpur',
    telephone: '',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@fonepoints.com',
    fb: 'https://www.facebook.com/profile.php?id=61551776243754',
    twitter: '',
    linkedin: 'https://www.linkedin.com/company/fonepoints/?viewAsMember=true',
    insta: 'https://www.instagram.com/fonepoints/',
    youtube: '',
    website: 'https://fonepoints.com/'
  },
  {
    img: '/img/logos/logo-08.png	',
    companyname: 'Logica Beans Pvt Ltd',
    companyaddress: 'Lal Colony Marga, Kathmandu',
    companyaddresssub: '',
    telephone: '4434586',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@logicabeans.com ',
    // website: 'www.logicabeans.com'
    fb: 'https://www.facebook.com/logicabeans',
    twitter: '',
    linkedin: 'https://www.linkedin.com/in/logica-beans-111606187/',
    insta: '',
    youtube: '',
    website: 'https://logicabeans.com/'
  },
  {
    img: '/img/logos/logo-cogent-health-contact2.png',
    companyname: 'Cogent Health Pvt Ltd',
    companyaddress: 'Pulchowk, Lalitpur',
    companyaddresssub: '',
    telephone: '5425261',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@cogenthealth.com.np',
    // website: 'www.cogenthealth.com.np'
    fb: 'https://www.facebook.com/cogenthealthnepal',
    twitter: '',
    linkedin: 'https://www.instagram.com/cogenthealthnepal/',
    insta: 'https://www.instagram.com/cogenthealth/',
    youtube: 'https://www.youtube.com/channel/UCqW7EbwKJIhHSEYem8yPYJA',
    website: 'http://www.cogenthealth.com.np/'
  },
  // {
  //   img: '/img/logos/logo-feat.png',
  //   companyname: 'Feat International Pvt Ltd',
  //   companyaddress: 'Sincere Business Complex, ',
  //   companyaddresssub: 'Pulchowk, Lalitpur',
  //   telephone: '9802079177',
  //   customersupp: '',
  //   trollfree: '',
  //   viber: '',
  //   whatsapp: '',
  //   email1: 'info@featinternational.com',
  //   // website: 'esewaremit.com'
  //   fb: 'https://www.facebook.com/FeatInternationalOfficial',
  //   twitter: 'https://twitter.com/FeatIntl',
  //   linkedin: 'https://www.linkedin.com/company/feat-international-pvt-ltd',
  //   insta: 'https://www.instagram.com/featinternationalofficial/',
  //   youtube: 'https://www.youtube.com/channel/UCEh6iJGmt_86-OVyQxVseCA',
  //   website: 'https://featinternational.com/'
  // },
  {
    img: '/img/logos/logo-11.png',
    companyname: 'Swastik College',
    companyaddress: 'Chardobato, Bhaktapur',
    companyaddresssub: '',
    telephone: '6635174',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@swastikcollege.edu.np',
    // website: 'swastikcollege.edu.np'
    fb: 'https://www.facebook.com/swastikcollegeofficial',
    twitter: '',
    linkedin: 'https://www.linkedin.com/school/swastik-college/',
    insta: '',
    youtube: '',
    website: 'https://swastikcollege.edu.np/'
  },
  {
    img: '/img/logos/logo-05.png',
    companyname: 'eXtenso Data Pvt Ltd',
    companyaddress: 'F1Soft Group Tower, Pulchowk, Lalitpur',
    companyaddresssub: '',
    telephone: '5439131',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'contact@extensodata.com',
    // website: 'extensodata.com'
    fb: 'https://www.facebook.com/extensodata',
    twitter: '',
    linkedin: 'https://www.linkedin.com/company/extensodata/',
    insta: '',
    youtube: '',
    website: 'http://www.extensodata.com/'
  },
  {
    img: '/img/logos/logo-01.png',
    companyname: 'Diyalo Technology Pvt Ltd',
    companyaddress: 'New Baneswor, Kathmandu',
    companyaddresssub: '',
    telephone: '4115944',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@diyalotech.com',
    // website: 'www.diyalotech.com'
    fb: 'https://www.facebook.com/Diyalo-Technologies-2791771974196581',
    twitter: '',
    linkedin: 'https://www.linkedin.com/company/diyalo-technologies-pvt-ltd/',
    insta: '',
    youtube: '',
    website: 'http://diyalotech.com/'
  },
  {
    img: '/img/logos/foneinsure.png',
    companyname: 'Foneinsure Pvt Ltd',
    companyaddress: 'Pulchowk- 03, Lalitpur',
    companyaddresssub: '',
    telephone: '+977-1-5439131',
    customersupp: '',
    trollfree: '',
    viber: '',
    whatsapp: '',
    email1: 'info@foneinsure.com.np',
    // website: 'www.darshantech.com.np'
    fb: '',
    twitter: '',
    linkedin: 'https://www.linkedin.com/company/foneinsure-pvt-ltd',
    insta: '',
    youtube: '',
    website: 'http://foneinsure.com.np'
  },
  // {
  //   img: '/img/logos/logo-10.png',
  //   companyname: 'Shiran Technologies',
  //   companyaddress: '5th Floor, Lalchan Plaza, ',
  //   companyaddresssub: 'Hattisar-1, Kathmandu, Nepal',
  //   telephone: '4414208',
  //   customersupp: '',
  //   trollfree: '',
  //   viber: '',
  //   whatsapp: '',
  //   email1: 'info@shirantech.com',
  //   // website: 'www.shirantech.com'
  //   fb: 'https://www.facebook.com/ShiranTech',
  //   twitter: '',
  //   linkedin:
  //     'https://www.linkedin.com/company/shiran-technologies-pvt-ltd/about/',
  //   insta: '',
  //   youtube: '',
  //   website: 'https://www.shirantech.com/'
  // },
]
