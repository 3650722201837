import React from 'react'
import { Row, Col } from 'reactstrap'
import './addingvalue.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Transition } from 'react-transition-group'
import { Link } from 'react-router-dom'

function AddingValue() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [transitionFirst, setTransitionFirst] = useState(false)
  const [transitionSecond, settransitionSecond] = useState(false)
  const [transitionThird, settransitionThird] = useState(false)
  const [transitionForth, settransitionForth] = useState(false)
  const duration = 200

  // text header transition
  const headerTextStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    position: 'absolute',
    top: 0
  }
  const headerTextTransition = {
    entering: { top: -15 },
    entered: { top: -85 },
    exiting: { top: -85 },
    exited: { top: -15 }
  }
  //  text-transition
  const defaultTextStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    position: 'absolute',
    height: 0,
    opacity: 0,
    top: 0
  }
  const defaultTextTransition = {
    entering: { opacity: 0, height: 0, top: -60 },
    entered: { opacity: 1, height: 0, top: -86 },
    exiting: { opacity: 1, height: 0, top: -86 },
    exited: { opacity: 0, height: 0, top: -60 }
  }
  // arrow transition
  const defaultArrowStyle = {
    transition: 300 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    marginLeft: 5,
    opacity: 0
  }
  const defaultArrowTransition = {
    entering: {
      marginLeft: 10,
      opacity: 0
    },
    entered: {
      marginLeft: 40,
      opacity: 1
    },
    exiting: {
      marginLeft: 35,
      opacity: 0
    },
    exited: {
      marginLeft: 5,
      opacity: 0
    }
  }

  return (
    <div className="adding-value">
      <Row className="row-no-padding">
        {/* <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => setTransitionFirst(true)}
          onMouseLeave={() => setTransitionFirst(false)}
        >
          <img src="/img/homepage/bg_bigdata.jpg" alt="" />
          <Transition in={transitionFirst} timeout={duration}>
            {state => {
              return (
                <Link to="/business/bigdata">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Analytics, Big Data & Forecasting
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        eXtensoData provides the tools required to optimize
                        daily operations, lower costs and identify new
                        opportunities
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div> */}
        {/* <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => settransitionForth(true)}
          onMouseLeave={() => settransitionForth(false)}
        >
          <img src="/img/homepage/bg_swastik.jpg" alt="" />
          <Transition in={transitionForth} timeout={duration}>
            {state => {
              return (
                <Link to="/business/education">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Hands-on Learning
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        The courses offered by Swastik College are tailored to
                        produce industry ready IT graduates with placements at
                        F1Soft group verticals
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        
        {/* </div> */}
      </Row>
      <Row className="row-no-padding">
        {/* className='row-no-padding'*/}
        {/* <Col
          className="figure"
          onMouseEnter={() => settransitionThird(true)}
          onMouseLeave={() => settransitionThird(false)}
        >
          <img src="/img/homepage/bg_application.jpg" alt="" />
          <Transition in={transitionThird} timeout={duration}>
            {state => {
              return (
                <Link to="/business/web-app-development">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        In-house & Enterprise Applications
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        From mobile apps to MIS, Shiran Technologies provides
                        high quality solutions that add value to business big
                        and small
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col> */}
        {/* </div>
        <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => settransitionSecond(true)}
          onMouseLeave={() => settransitionSecond(false)}
        >
          <img src="/img/homepage/bg_research.jpg" alt="" />
          <Transition in={transitionSecond} timeout={duration}>
            {state => {
              return (
                <Link to="/business/offshoring">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Research & Development
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        LogicaBeans helps in-source cutting edge development
                        tools and techniques that are applied to multi-sectorial
                        use cases
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div> */}
      </Row>
    </div>
  )
}

export default AddingValue
