import React, { Fragment } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Newsletter from '../../components/media/Newsletter'
import StickyLayout from '../../layout/StickyLayout'
import { mediaBannerItem, mediaBannerNav } from './BannerMedia'

function Tenders(props) {
  // API :GET
  //   const {
  //     status,
  //     data: newsLetter,
  //     isLoading: newsletterLoading
  //   } = useQuery(ApiNames.NEWS_LETTER, () => fetchData(ApiNames.NEWS_LETTER))
  const newsLetter = {
    data: [
      {
        title:
          'Call for Expression of Interest (EOI) from Insurance Companies for Property Insurance of F1soft Group of Companies',
        cover: '/tenders/EOI.jpg',
        file: '/tenders/EOI.jpg'
      }
    ]
  }

  return (
    <StickyLayout bannerItem={mediaBannerItem} bannerNav={mediaBannerNav}>
      <Fragment>
        <div className="news-letter tenders">
          <Container>
            <Row>
              {newsLetter &&
                newsLetter.data !== null &&
                newsLetter.data.map(newsletterItem => (
                  <Col md="4" title="Call for Expression of Interest (EOI) from Banks for Banking Solutions, Benefits and Facilities for Employees of the F1Soft group of Companies Notice No FY 207980-GSD-003">
                    <Newsletter
                      newsletterItem={newsletterItem}
                      isLoading={false}
                      imageUrl={''}
                    />
                  </Col>
                ))}
            </Row>
          </Container>
        </div>
      </Fragment>
    </StickyLayout>
  )
}
export default Tenders
