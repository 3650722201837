import React, { Fragment, useEffect } from 'react'
// import { Container } from 'reactstrap';
// import { Row } from 'reactstrap';
// import { Colxx } from '../../components/common/CustomBootstrap';
// import { Link, useHistory } from "react-router-dom";

import BannerNav from '../../components/BannerNav'
import ContactdetailCard from '../../components/contact/ContactdetailCard'

import { contactDetails } from '../../data/contact'
import { Container, Row, Col } from 'reactstrap'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import '../../containers/navs/breadcrumb.scss'
import { scrollToTop } from '../../utils/scrollToTop'

function DefaultDashboard(props) {
  const bannerItem = {
    image: '/img/f1soft_contact.jpg',
    title: 'Contact',
    caption: 'Get in touch'
  }
  useEffect(() => {
    // Scroll to top of the page on route change
    scrollToTop()

    document.body.className = ''
    return () => {
      document.body.className = ''
    }
  }, [])

  return (
    <Fragment>
      <BannerNav className="" bannerItem={bannerItem} />
      <Path />
      <div className="contact-details">
        <Container>
          <Row>
            {contactDetails &&
              contactDetails.map(contactItem => (
                <Col md="4">
                  <ContactdetailCard contactItem={contactItem} />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
function Path() {
  return (
    <div className="breadcrumb-other d-none d-md-block">
      <Breadcrumb>
        <Container>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>

          <BreadcrumbItem active>Contact</BreadcrumbItem>
        </Container>
      </Breadcrumb>
    </div>
  )
}
export default DefaultDashboard
