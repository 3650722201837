import React from 'react'
import './mobile-menu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Transition } from 'react-transition-group'
import aboutDropdownlink from '../../data/mobile-menu/about-dropdownlink'
import businessDropdownlink from '../../data/mobile-menu/business-dropdownlink'
import mediaDropdownlink from '../../data/mobile-menu/media-dropdownlink'
import { Link } from 'react-router-dom'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

const duration = 300
// transition for bg-red
const defaultStyle = {
  transition: 500 + 'ms',
  opacity: 1,
  position: 'fixed',
  backgroundColor: 'white',
  width: 300,
  height: '100vh',
  right: -300,
  top: 0,
  zIndex: 99
}
// bg-white width
const widthTransition = {
  entering: {
    right: -300,
    opacity: 1,
    backgroundColor: 'rgba(247, 241, 241, 0.6)'
  },
  entered: {
    right: 0,
    opacity: 1,
    backgroundColor: 'white'
  },
  exiting: {
    right: 0,
    opacity: 1,
    backgroundColor: 'white'
  },
  exited: {
    right: -300,
    opacity: 0,
    backgroundColor: 'rgba(247, 241, 241, 0.6)'
  }
}
const backdropStyle = {
  transition: duration + 'ms',
  opacity: 1,
  position: 'fixed',
  display: 'block',
  backgroundColor: 'black',
  width: '100vw',
  height: '100vh',
  right: 2,
  zIndex: -1
}
const backdrop = {
  entering: { opacity: 0, display: 'none' },
  entered: { opacity: 0.75, display: 'block' },
  exiting: { opacity: 0.75, display: 'block' },
  exited: { opacity: 0, display: 'none' }
}

function MobileMenu() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  return (
    <div className="mobile-menu  d-md-none">
      <div className="position-relative clearfix">
        <div className="logo float-left">
          <Link to="/">
            {' '}
            <img src="/img/logo.png" alt="logo" />
          </Link>
        </div>
        {/* start */}

        <Transition in={sidebarOpen} timeout={duration}>
          {state => {
            return (
              <div
                className="blackdrop "
                style={{ ...backdropStyle, ...backdrop[state] }}
                onClick={() => toggleSidebar()}
              >
                {' '}
              </div>
            )
          }}
        </Transition>

        {/* end */}

        <div className="menu-bar float-right" onClick={() => toggleSidebar()}>
          <FontAwesomeIcon icon="bars" />
        </div>
        {/* sidebar */}
        <div className="sidebar">
          <Transition in={sidebarOpen} timeout={duration}>
            {state => {
              return (
                <div
                  className="absolute"
                  style={{ ...defaultStyle, ...widthTransition[state] }}
                >
                  <div className="menu ">
                    <div
                      className="close-button"
                      onClick={() => toggleSidebar()}
                    >
                      <FontAwesomeIcon icon="times" />
                    </div>

                    <ul className="menu-items ">
                      <li className="menu-list">
                        <CustomDropdown
                          parentDropdownName="About"
                          subDropdownLink={aboutDropdownlink}
                          toggleSidebar={toggleSidebar}
                        />
                      </li>

                      <li className="menu-list">
                        <CustomDropdown
                          parentDropdownName="Business"
                          subDropdownLink={businessDropdownlink}
                          toggleSidebar={toggleSidebar}
                        />
                      </li>
                      <li className="menu-list">
                        <CustomDropdown
                          parentDropdownName="Media"
                          subDropdownLink={mediaDropdownlink}
                          toggleSidebar={toggleSidebar}
                        />
                      </li>
                      <li className="menu-list" onClick={() => toggleSidebar()}>
                        <Link to="/career" className="menu-link">
                          Career
                        </Link>
                      </li>
                      <li className="menu-list" onClick={() => toggleSidebar()}>
                        <a
                          href="https://f1soft.com/blog/"
                          className="menu-link"
                        >
                          Blog
                        </a>
                      </li>
                      <li className="menu-list" onClick={() => toggleSidebar()}>
                        <Link to="/contact" className="menu-link">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )
            }}
          </Transition>
        </div>
      </div>
    </div>
  )
}

function CustomDropdown(props) {
  const { parentDropdownName, subDropdownLink, toggleSidebar } = props

  return (
    <UncontrolledDropdown setActiveFromChild>
      <DropdownToggle tag={Link} to="#!" className="nav-link" caret>
        {parentDropdownName}
      </DropdownToggle>
      {subDropdownLink.length !== 0 && (
        <DropdownMenu>
          {subDropdownLink.map(item => (
            <DropdownItem
              key={item.label}
              tag={Link}
              to={item.url}
              active
              onClick={() => toggleSidebar()}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  )
}

export default MobileMenu
