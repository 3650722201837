import React from 'react';
import { Col } from 'reactstrap';
const Colxx = (props) => (
  <Col
    {...props}
    widths={['col', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']}
  />
);
const Separator = (props) => (
  <div
    className={`separator ${props.className ? props.className : 'mt-4 mb-4'}`}
  ></div>
);
export { Colxx, Separator };
