import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import CorporateNews from './corporatenews'
import NewsDetails from './news-details'
import NewsLetter from './newsletter'
import Videos from './videos'
import Tenders from './tenders'
// import SocialMedia from './socialmedia'
// import SocialmediaDetails from './socialmedia-details';
import { useRouteMatch } from 'react-router-dom'
// import Page from 'react-page-loading'
// import StickyLayout from '../../layout/StickyLayout'

// const bannerItem = {
//   image: '/img/bg_media.jpg',
//   title: 'Media',
//   caption: ' Latest updates, media coverage, newsletters & videos'
// }

// const bannerNav = [
//   {
//     name: 'Corporate News',
//     url: '/media/corporatenews'
//   },
//   {
//     name: 'Newsletter',
//     url: '/media/newsletter'
//   },
//   {
//     name: 'Videos',
//     url: '/media/videos'
//   }
//   // {
//   //   name: 'Social Media',
//   //   url: '/media/socialmedia'
//   // }
// ]

const Dashboards = () => {
  const match = useRouteMatch()
  // console.log(match)
  const pageLoader = {
    position: 'absolute',
    left: 0,
    top: 200
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="dashboard-wrapper">
      <div
        className="page-loader"
        style={{ ...pageLoader, ...{ position: 'relative' } }}
      >
        {/* <Page loader={'rotate-spin'} color={'#b22222'} size={3} duration={5} /> */}
      </div>

      {/* <BannerNav className="" bannerItem={bannerItem} bannerNav={bannerNav} /> */}
      {/* <StickyLayout
       bannerItem={bannerItem} bannerNav={bannerNav}
      > */}
      <Switch>
        <Redirect
          exact
          from={`${match.path}/`}
          to={`${match.path}/corporatenews`}
        />
        <Route
          path={`${match.path}/corporatenews/:id`}
          component={NewsDetails}
        />
        <Redirect
          exact
          from={`${match.path}/`}
          to={`${match.path}/newsletter`}
        />

        {/* <Route path={`${match.path}/newsletter/:id`} component={NewsDetails} /> */}

        <Route path={`${match.path}/corporatenews`} component={CorporateNews} />
        <Route path={`${match.path}/newsletter`} component={NewsLetter} />
        <Route path={`${match.path}/videos`} component={Videos} />
        <Route path={`${match.path}/tenders`} component={Tenders} />
        {/* <Route path={`${match.path}/socialmedia`} component={SocialMedia} /> */}
      </Switch>
      {/* </StickyLayout> */}
    </div>
  )
}
export default Dashboards
