import React, { useEffect } from 'react'
// import Page from 'react-page-loading'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import StickyLayout from '../../layout/StickyLayout'
import Company from './company'
import Ecosystem from './ecosystem'
import Impact from './impact'
import Leadership from './leadership'
import Milestone from './milestone'
// import BannerAbout from './BannerAbout'

const bannerItem = {
  image: '/img/bg_about.jpg',
  title: 'About Us',
  caption:
    'Connecting people to financial services through technology and enabling their inclusion in the formal economy'
}

const bannerNav = [
  {
    name: 'The Company',
    url: '/about/company'
  },
  {
    name: 'Ecosystem',
    url: '/about/ecosystem'
  },
  {
    name: 'Impact',
    url: '/about/impact'
  },
  {
    name: 'Milestones',
    url: '/about/milestone'
  },
  {
    name: 'Leadership ',
    url: '/about/leadership'
  }
]

const Dashboards = React.memo(() => {
  const match = useRouteMatch()
  // console.log(match);
  const pageLoader = {
    position: 'absolute',
    left: 0,
    top: 200
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="dashboard-wrapper">
      <div
        className="page-loader"
        style={{ ...pageLoader, ...{ position: 'relative' } }}
      >
        {/* <Page loader={'rotate-spin'} color={'#b22222'} size={3} duration={2} /> */}
      </div>

      <StickyLayout bannerItem={bannerItem} bannerNav={bannerNav}>
        <Switch>
          {/* <Redirect
          exact
          from={`${match.path}/`}
          to={`${match.path}/leadership`}
        />
        <Route
          path={`${match.path}/leadership/:id`}
          component={LeadershipDetail}
        /> */}
          <Route path={`${match.path}/leadership`} component={Leadership} /> */}
          {/* <Redirect exact from={`${match.path}/`} to={`${match.path}/company`} /> */}
          <Route path={`${match.path}/company`} component={Company} />
          <Route path={`${match.path}/ecosystem`} component={Ecosystem} />
          <Route path={`${match.path}/impact`} component={Impact} />
          <Route path={`${match.path}/milestone`} component={Milestone} />
          {/* <Route path={`${match.path}/:id/add`} component={AddMobileApps} /> */}
          <Redirect to="/error" />
        </Switch>
      </StickyLayout>
    </div>
  )
})

export default Dashboards
