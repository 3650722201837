import React, { Fragment, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Modal } from 'reactstrap'
import { Card, CardText, CardBody, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { ApiNames, fetchData } from '../../remote-data/fetchData'
import { useQuery } from 'react-query'
import { NewsLetterLoader } from '../../utils/shimmer'
import StickyLayout from '../../layout/StickyLayout'
import { mediaBannerItem, mediaBannerNav } from './BannerMedia'
import Pagination from '../../containers/pages/Pagination'

function CorporateNews(props) {
  const [currentPage, setCurrentPage] = useState(1) //page: 1
  //API:GET
  const { status, data: videoClip, isLoading: videoLoading } = useQuery(
    [ApiNames.VIDEOS, { currentPage }],
    () =>
      fetchData(
        ApiNames.VIDEOS,
        {
          params: {
            page: currentPage
          }
        },
        {},
        true
      )
  )
  const videoList = videoClip?.data?.data
  const videoHeaders = videoClip?.headers
  const totalPage = videoHeaders && videoHeaders['x-pagination-page-count']

  return (
    <StickyLayout bannerItem={mediaBannerItem} bannerNav={mediaBannerNav}>
      <Fragment>
        <div className="videos">
          <Container>
            {(status === 'loading' || videoLoading) && (
              <Row>
                <Col sm="6" lg="4" md="4">
                  <NewsLetterLoader />
                </Col>
                <Col sm="6" lg="4" md="4">
                  <NewsLetterLoader />
                </Col>
                <Col sm="6" lg="4" md="4">
                  <NewsLetterLoader />
                </Col>
              </Row>
            )}
            <Row>
              {videoList &&
                videoList != null &&
                videoList.map(videoItem => (
                  <Col md="4">
                    <VideoClipItem
                      videoItem={videoItem}
                      isLoading={videoLoading}
                    />
                  </Col>
                ))}
            </Row>
            <Row className="d-flex justify-content-center">
              {totalPage && (
                <Pagination
                  currentPage={currentPage}
                  // totalPage={Math.ceil(pagination.totalCount / pagination.size)}
                  totalPage={totalPage}
                  numberLimit={5}
                  onChangePage={i => {
                    // console.log(i)
                    setCurrentPage(i) // - 1
                  }}
                />
              )}
            </Row>
          </Container>
        </div>
      </Fragment>
    </StickyLayout>
  )
}
function VideoClipItem(props) {
  const { videoItem } = props
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <div className="videos-section">
      <Card onClick={toggle}>
        <div className="videosrc">
          <div className="play-button">
            <FontAwesomeIcon icon="play" />
          </div>
          <img
            src={
              'https://img.youtube.com/vi/' +
              videoItem.link.split('embed/')[1] +
              '/mqdefault.jpg'
            }
            alt={videoItem.title}
            width={350}
            height={196}
            style={{ objectFit: 'cover' }}
          />
        </div>

        <CardBody>
          <CardTitle>{videoItem.title}</CardTitle>
          <CardText>{videoItem.videotext}</CardText>
          <CardText>
            <small>
              {dayjs(videoItem.created * 1000).format('MMM DD, YYYY')}
            </small>
          </CardText>
        </CardBody>
        <Modal isOpen={modal} toggle={toggle}>
          <div className="videosrc">
            <iframe
              width="100%"
              height="500px"
              src={videoItem.link}
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
              title={videoItem.title}
            />
          </div>
        </Modal>
      </Card>
    </div>
  )
}

export default CorporateNews
