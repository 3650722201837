const aboutDropdownlink = [
  {
    label: 'The Company',
    url: '/about/company',
  },
  {
    label: 'Ecosystem',
    url: '/about/ecosystem',
  },
  {
    label: 'Impact',
    url: '/about/impact',
  },
  {
    label: 'Milestone',
    url: '/about/milestone',
  },
  {
    label: 'Leadership',
    url: '/about/leadership',
  },
];

export default aboutDropdownlink;
