import React, { Fragment, useEffect, useState } from 'react'
import { director, leadership} from '../../data/about/leadership'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { scrollToTop } from '../../utils/scrollToTop'
import {
  // Modal,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Container,
  Row,
  Col
} from 'reactstrap'
//  import LeaderCard from '../../components/about/LeaderCard';
// import director from '../../data/about/leadership';
// import LeadershipDetail from '../../views/about/leadership-detailpage';

function Company(props) {
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <>
        <Container>
          <div className="mainprofile">
            <div className="director">
              <h4>Board of Directors</h4>
              <Row>
                {director &&
                  director.map((item, index) => (
                    <LeaderShipItem key={index} directorItem={item} />
                  ))}
              </Row>
            </div>

            <div className="senior-mng">
              <h4>Leadership Team</h4>
              <Row>
                {leadership &&
                  leadership.map((item, index) => (
                    <LeaderShipItem key={index} directorItem={item} />
                  ))}
              </Row>
            </div>

            {/* <div className="senior-mng">
              <h4>Management Team</h4>
              <Row>
                {senior &&
                  senior.map((item, index) => (
                    <LeaderShipItem key={index} directorItem={item} />
                  ))}
              </Row>
            </div> */}
          </div>
        </Container>
      </>
    </Fragment>
  )
}
function LeaderShipItem(props) {
  const { directorItem } = props
  const [modal, setModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
  }

  // const leadershipImage = leadershipItem && leadershipItem.img;
  const directorImage = directorItem && directorItem.img

  // const leadershipName = leadershipItem && leadershipItem.leadername;
  const directorName = directorItem && directorItem.leadername

  // const leadershipPosition = leadershipItem && leadershipItem.leaderposition;
  const directorPosition = directorItem && directorItem.leaderposition

  // const leadershipCompany = leadershipItem && leadershipItem.leadercompany;
  const directorCompany = directorItem && directorItem.leadercompany

  // const leadershipLinkedin = leadershipItem && leadershipItem.leaderLinkedin;
  const directorLinkedin = directorItem && directorItem.linkedin
  return (
    <>
      <Col md="4">
        {/* <LeaderCard leadershipItem={leadershipItem} onClick={toggle} /> */}

        <div className="leadership">
          <Card onClick={toggle}>
            <CardImg top width="100%" src={`${directorImage}`} alt="" />
            <CardBody>
              <CardTitle>
                {/* <Link to={`${match.url}/${leadershipItem.id}`} onClick={toggle}> */}
                {/* {leadershipItem.id} */}
                {/* {leadershipName} */}
                {directorName}
                {/* </Link> */}
              </CardTitle>
              <CardSubtitle>
                {/* {leadershipPosition} */}
                {directorPosition}
              </CardSubtitle>
              <CardText>
                {/* {leadershipCompany} */}
                {directorCompany}
              </CardText>
              {directorLinkedin && (
                <div className="media">
                  <ul className="follow-us">
                    <li className="linkedin">
                      <a
                        href={`${directorLinkedin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </CardBody>
          </Card>
        </div>

        {/* <Modal isOpen={modal} toggle={toggle}> */}
        {/* <LeadershipDetail toggle={toggle} /> */}
        {/* <LeadershipDetail toggle={toggle} leadershipItem={leadershipItem} /> 
      </Modal>
      */}
      </Col>
    </>
  )
}
export default Company
