import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function FinTech(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="fintech">
        <Container>
          <div className="esewa-header clearfix">
            <div className=" logo  mb-3">
              <img src="/img/logo-200.png" alt="" />
            </div>

            <div className=" media ">
              <ul className=" follow-us">
                <li className="facebook">
                  <a
                    href="https://www.facebook.com/f1soft"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
                <li className="twitter">
                  <a
                    href="https://twitter.com/f1soft?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </a>
                </li>
                <li className="youtube">
                  <a
                    href=" https://www.youtube.com/user/F1softInternational"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                  </a>
                </li>
                <li className="linkedin">
                  <a
                    href="https://www.linkedin.com/company/f1softgroup/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="esewa-body">
            <p className="text-muted">
              {/* F1Soft Pvt. Ltd. is Nepal’s leading Fintech company providing
              transactional banking services to the banks and financial
              institutions in Nepal. F1Soft is recognized for introducing mobile
              banking and mobile financial services in the country. The company
              develops fintech solutions aimed at simplifying the access and use
              of financial services by individuals and organizations.
              Established in 2004, it is the founding company of the F1Soft
              Group. */}
              F1Soft Pvt. Ltd. is Nepal’s leading fintech company, providing 
              transactional banking services to banks and financial institutions 
              in Nepal. F1Soft is recognized for introducing mobile banking and 
              mobile financial services in the country. The company develops 
              fintech solutions aimed at simplifying access to and the use of 
              financial services by individuals and organizations. Established 
              in 2004, it is the founding company of the F1Soft Group.
              <br />
              <br />
              {/* The Mobile Banking and Internet Banking platforms developed by
              F1Soft are currently used by over 90% of the banks in Nepal
              serving in excess of 19M people. The systems contribute to nearly
              80% of the total digital payments in Nepal. The company’s efforts
              in FinTech innovation have been duly recognized by various
              national and international bodies including the 2014 International
              Business Awards (Bronze), 2013 FNCCI Service Excellence Award and
              the 2012 Red Herring Top 100 Asia Award. In 2017, F1Soft was
              recognized as Nepal’s Highest Tax Payer (IT Sector) by the
              Government of Nepal. */}
              The Mobile Banking and Internet Banking platforms developed by F1Soft 
              are currently used by over 90% of the banks in Nepal, serving in excess 
              of 19 million people. The systems contribute to nearly 80% of the total 
              digital payments in Nepal. The company’s efforts in fintech innovation 
              have been duly recognized by various national and international bodies, 
              including the 2014 International Business Awards (Bronze), the 2013 
              FNCCI Service Excellence Award, and the 2012 Red Herring Top 100 Asia 
              Award. In 2017, F1Soft was recognized as Nepal’s Highest Tax Payer 
              (IT Sector) by the Government of Nepal.
              <br />
              <br />
              {/* F1Soft is also working with the Government of Nepal and a few
              other organizations to identify and develop digital financial
              solutions that benefit and improve the lives of the unbanked and
              underbanked across the country. */}
              F1Soft is also working with the Government of Nepal and a few other 
              organizations to identify and develop digital financial solutions that 
              benefit and improve the lives of the unbanked and underbanked across 
              the country.
            </p>
          </div>

          <div className="img-collection">
            <div className=" position-relative">
              <h5>Featured Products</h5>
              <div className="underline">
                {' '}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </div>
            </div>

            <Row>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/1.png"
                  alt=""
                />
              </Col>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/7-updated.png"
                  alt=""
                />
              </Col>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/5-updated.png"
                  alt=""
                />
              </Col>

              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/6.png"
                  alt=""
                />
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/2.png"
                  alt=""
                />
              </Col>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/8.jpg"
                  alt=""
                />
              </Col>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/bizmessages.png"
                  alt=""
                />
              </Col>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/3.png"
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/10-updated.png"
                  alt=""
                />
              </Col>
              <Col md="3">
                <img
                  className="photo"
                  src="/img/business/fintech/fee-ms.png"
                  alt=""
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
export default FinTech
