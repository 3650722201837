import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function PaymentProcessing(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="paymentprocessing">
        <Container>
          <Row>
            <Col md="7">
              <div className="esewa-header clearfix">
                <div className=" logo">
                  <img src="/img/logos/logo-06.png" alt="" />
                </div>

                <div className=" media ">
                  <ul className=" follow-us">
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/fonepay"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/fonepaynepal/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://www.linkedin.com/company/fonepay/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </a>
                    </li>
                    <li className="twitter">
                      <a
                        href="https://twitter.com/fonepay"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li>

                    <li className="youtube">
                      <a
                        href="https://www.youtube.com/channel/UCgVAQRwA0UKFUhXPK1xd2bA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'youtube']} />
                      </a>
                    </li>
                    <li className="website">
                      <a
                        href="https://www.fonepay.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                        <img src="/img/www.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                  {/* Fonepay is a digital payment processor that connects
                  consumers, banks and merchants in an interoperable network to
                  facilitate mobile/digital payments. It is Nepal’s first mobile
                  payment network to be licensed by the central bank as a
                  Payment Service Operator (PSO). Fonepay enables the following
                  digital payments: */}
                  Fonepay is a digital payment processor that connects consumers, 
                  banks, and merchants in an interoperable network to facilitate 
                  mobile/digital payments. It is Nepal’s first mobile payment network 
                  licensed by the central bank as a Payment Service Operator (PSO). 
                  Fonepay enables the following digital payments:
                  <br />
                  <br />
                  <ul>
                    <li>
                      {/* In-Store Merchant Payments (QR Payments): Customers use
                      the issuing app (generally the mobile banking app of a
                      member bank) to scan the fonepay QR code available
                      Merchant outlets. Merchants can provide printed static QR
                      codes or dynamic QR codes through the merchant
                      app/portal/billing systems. */}
                      In-Store Merchant Payments (QR Payments): Customers use the 
                      issuing app (generally the mobile banking app of a member bank) 
                      to scan the Fonepay QR code available at merchant outlets. 
                      Merchants can provide printed static QR codes or dynamic QR 
                      codes through the merchant app/portal/billing systems.
                    </li>

                    <li>
                      {/* Online Payments: Customers can use the credentials of
                      their issuing app to make payments for online services
                      such as utility payments, flight tickets, movie tickets
                      etc */}
                      Online Payments: Customers can use the credentials of their 
                      issuing app to make payments for online services such as 
                      utility payments, flight tickets, movie tickets, etc.
                    </li>
                    <li>
                      {/* P2P Fund Transfers: Customers can use the Inter Bank Fund
                      Transfer to transfer funds from one bank to any other bank
                      in the network using the recipient’s mobile number or
                      account number. */}
                      P2P Fund Transfers: Customers can use Inter-Bank Fund Transfer 
                      to transfer funds from one bank to any other bank in the network 
                      using the recipient’s mobile number or account number.
                    </li>
                  </ul>
                  <br />
                  The following mobile apps and web applications are used to 
                  make and monitor transactions in the Fonepay network:
                  <br />
                  <br />
                  <ul>
                    <li>
                      {/* Issuing App: Fonepay is integrated with each Issuing
                      Bank’s mobile banking app and internet banking service.
                      Hence, the issuing app is each bank’s individual mobile
                      banking app or internet banking application. For member
                      wallets, the issuing app is the wallet app.  */}
                      Issuing App: Fonepay is integrated with each Issuing Bank’s 
                      mobile banking app and internet banking service. Hence, the 
                      issuing app is each bank’s individual mobile banking app or 
                      internet banking application. For member wallets, the issuing 
                      app is the wallet app.
                    </li>
                    <li>
                      {/* Merchant App/Web Portal: Merchants on the fonepay network
                      have access to the Merchant App and web portal using which
                      they can monitor the status of transactions and transfer
                      fund to their bank accounts at any time. Merchants can
                      also generate dynamic QR codes for each payments using the
                      app/portal. */}
                      Merchant App/Web Portal: Merchants on the Fonepay network have 
                      access to the Merchant App and web portal, through which they 
                      can monitor the status of transactions and transfer funds to 
                      their bank accounts at any time. Merchants can also generate 
                      dynamic QR codes for each payment using the app/portal.
                    </li>
                    <li>
                      {/* Offer App: This is an informative app which does not
                      conduct any transactions. Customers with this app can find
                      the latest and best deals, discounts and offers made
                      available by the merchants on the Fonepay network.
                      Customers receive notifications depending on their
                      geolocation and can also filter the offers based on
                      various parameters. */}
                      Offer App: This is an informative app that does not conduct 
                      any transactions. Customers with this app can find the latest 
                      and best deals, discounts, and offers made available by the 
                      merchants on the Fonepay network. Customers receive notifications 
                      depending on their geolocation and can also filter the offers 
                      based on various parameters.
                    </li>
                    <li>
                      {/* FoneTag: FoneTag is a method of offline payments available 
                      from the Fonepay app. Customers can use NFC or customer-presented 
                      QRs to make digital payments without the need for internet. For 
                      offline payments via FoneTag, it is necessary to link bank accounts 
                      prior to payment. */}
                      FoneTag: FoneTag is a method of offline payments available from the 
                      Fonepay app. Customers can use NFC or customer-presented QR codes to 
                      make digital payments without the need for internet. For offline 
                      payments via FoneTag, it is necessary to link bank accounts prior to payment.
                    </li>
                  </ul>
                </p>
              </div>

              <div className="app ">
                <div className="merchant-app">
                  <h5>Merchant App</h5>
                  <ul className="clearfix">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.f1soft.fonepay.merchant&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/anroid.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/np/app/fonepay-merchant/id1465584249"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/ios.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="offer-app">
                  <h5>Offer App</h5>
                  <ul className=" clearfix">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.fonepay&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/anroid.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/us/app/fonepay-offer-app/id1478083267"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/ios.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md="5">
              <img
                className="img-collection img-responsive"
                src="/img/business/payment-processing/fonepay_web.png"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default PaymentProcessing
