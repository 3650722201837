import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function Remittance(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="remittance">
        <Container>
          <Row>
            <Col>
              <div className="esewa-header clearfix">
                <div className="logo">
                  <img src="/img/logos/logo-14.png" alt="" />
                </div>

                <div className="media">
                  <ul className=" follow-us">
                    <li className="facebook">
                      <a
                        href=" https://www.facebook.com/esewamoneytransfer"
                        target="blank"
                      >
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </a>
                    </li>
                    {/* <li className="twitter">
                      <a href="https://twitter.com/fonepay">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li> */}
                    <li className="instagram">
                      <a
                        href=" https://www.instagram.com/esewa_moneytransfer/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://www.linkedin.com/company/esewa-money-transfer/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </a>
                    </li>
                    {/* <li className="youtube">
                      <a href="https://www.youtube.com/channel/UCgVAQRwA0UKFUhXPK1xd2bA">
                        <FontAwesomeIcon icon={['fab', 'youtube']} />
                      </a>
                    </li> */}
                    <li className="website">
                      <a
                        href="https://esewaremit.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                        <img src="/img/www.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                  {/* eSewa Money Transfer is one of the fastest growing remittance
                  companies in Nepal that offers a blended model of world-class
                  remittance services to Nepalese around the world. It’s service
                  offerings are comprised of traditional brick-and-mortar and
                  agent-based remittance services coupled with a host of
                  non-traditional, digital mode of transfers where sending and
                  receiving money is as easy as giving a few taps on a mobile
                  phone. */}
                  Esewa Money Transfer is one of the fastest-growing remittance 
                  companies in Nepal that offers a blended model of world-class 
                  remittance services to Nepalese around the world. Its service 
                  offerings are comprised of traditional brick-and-mortar and 
                  agent-based remittance services, coupled with a host of 
                  non-traditional digital modes of transfers, where sending and 
                  receiving money is as easy as giving a few taps on a mobile phone.
                  <br />
                  <br />
                  {/* Incorporated in the year 2019 and licensed by Nepal Rastra
                  Bank under foreign exchange regulation, eSewa Money Transfer
                  is a part of the F1soft Group and aims to not only leverage on
                  the group’s unparalleled competencies in the payment sector
                  but also to carry on the group’s legacy of empowering more
                  than 9 million population and 100K entities across the
                  country. */}
                  Incorporated in 2019 and licensed by Nepal Rastra Bank under 
                  foreign exchange regulations, Esewa Money Transfer is a part 
                  of the F1soft Group. It aims not only to leverage the group’s 
                  unparalleled competencies in the payment sector but also to 
                  carry on the group’s legacy of empowering more than 9 million 
                  people and 100,000 entities across the country.
                  <br />
                  <br />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default Remittance
