import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import './infosection.scss';
import OurSolution from './OurSolution';
import { Link } from 'react-router-dom';

function InfoSection() {
  return (
    <div className="info-section">
      <Container>
        <div className="text-section">
          <OurSolution />
        </div>
        <div className="category-section">
          <Row>
            <Col md="4">
              <Card>
                <CardBody>
                  <Link
                    to="/about/company"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <CardTitle>
                      {/* <FontAwesomeIcon icon='info' /> */}
                      <img src="/img/36.svg" alt="" height="60px" />
                    </CardTitle>
                    <CardSubtitle>About Us</CardSubtitle>
                    <CardText>
                      We are a Digital Financial Services holding company with
                      over 20 years of operational experience.
                    </CardText>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <Link
                    to="/about/milestone"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <CardTitle>
                      {/* <FontAwesomeIcon icon='flag' /> */}
                      <img src="/img/37.svg" alt="" height="60px" />
                    </CardTitle>
                    <CardSubtitle>Milestones</CardSubtitle>
                    <CardText>
                      Our story told through our achievements and the events
                      that have defined us over the years.
                    </CardText>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <Link
                    to="/about/leadership"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <CardTitle>
                      {/* <FontAwesomeIcon icon='users' /> */}
                      <img src="/img/38.svg" alt="" height="60px" />
                    </CardTitle>
                    <CardSubtitle>Leadership</CardSubtitle>
                    <CardText>
                      Key people driving our verticals towards a shared mission
                      to connect people through digital finance.
                    </CardText>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default InfoSection;
