import React, { useRef, useEffect } from 'react'
import 'react-slidedown/lib/slidedown.css'
import {
  NavLink,
  //  useParams,
  useRouteMatch
} from 'react-router-dom'
import {
  Nav,
  NavItem
  // NavLink
} from 'reactstrap'
// import CustomVideo from './video/CustomVideo';
import Html5Video from './video/Html5Video'
import { SingleLoader, VideoLoader } from '../utils/shimmer'
// import { useWindowScroll } from 'react-use'

// const BannerNav = (props) => {

export function BannerImg({ isLoading, className, bannerItem }) {
  const bannerImg = bannerItem && bannerItem.image
  const bannerTitle = bannerItem && bannerItem.title
  const bannerCaption = bannerItem && bannerItem.caption

  return (
    <>
      {/* {console.log('loading', isLoading)} */}
      {isLoading ? (
        <div
          className="shimmer overflow-hidden"
          style={{ position: 'inherit' }}
        >
          <SingleLoader />
        </div>
      ) : (
        <div
          className={`topnav banner-nav position-relative ${className}`}
          style={
            {
              // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)),url('${bannerImg}')`
            }
          }
        >
          <img src={bannerImg} alt="" />
          <div className="shimmer overflow-hidden">
            <SingleLoader />
          </div>

          <div className="container">
            {/* <SlideDown className={'my-dropdown-slidedown'}>
              {props.open ? props.children : null}
            </SlideDown> */}

            <div className="ban-text position-relative  ">
              <div className="banner-text ">
                <div className="connecting-people">
                  <span>{bannerTitle}</span>
                </div>
                <div className="sub-text">{bannerCaption}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export function BannerMenu({ bannerNav }) {
  const match = useRouteMatch()

  const myRef = useRef()

  useEffect(() => {
    // is this working ?
    if (myRef.current) {
      myRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  }, [])

  if (!bannerNav) return null

  return (
    <div className="position-relative">
      <div className="subnav d-none d-md-block">
        <div className="container">
          <div className="sublist">
            <Nav className="mr-auto">
              {bannerNav.map(
                (item, index) =>
                  console.log() || (
                    <NavItem key={index} className="">
                      <NavLink
                        className="normal nav-link"
                        ref={match.url.includes(item.ref) ? myRef : null}
                        // activeClassName="active"
                        exact
                        to={item.url}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  )
              )}
            </Nav>
          </div>
        </div>
      </div>
    </div>
  )
}

const BannerNav = ({
  className,
  bannerItem,
  bannerNav,
  isLoading,
  isVideo
}) => {
  // here edit

  // useEffect(() => {
  //   var dat = document.getElementById('imploader')
  //   if (dat) {
  //     setTimeout(() => {
  //       dat.setAttribute('hidden', 'true')
  //     }, 500)
  //   }
  // })

  // here end
  // const { y: pageYOffset } = useWindowScroll()
  // const [stick, setStick] = useState(false)
  // useEffect(() => {
  //   if (pageYOffset > 200) {
  //     console.log(pageYOffset)
  //     setStick(true)
  //   } else {
  //     setStick(false)
  //   }
  // }, [pageYOffset])
  // console.log('stickkkt', stick)
  // if (!stick) {
  //   return true
  // }

  return (
    <>
      {/* by using settimeout() */}
      {/* <div id="imploader">
        <ImpactLoader />
      </div> */}

      {/* Other elements can be in between `StickyContainer` and `Sticky`,
        but certain styles can break the positioning logic used. */}
      {/* banner */}
      {isVideo ? (
        <div className="position-relative">
          <Html5Video />
          <div className="shimmer overflow-hidden">
            <VideoLoader />
          </div>
        </div>
      ) : (
        <BannerImg
          isLoading={isLoading}
          className={className}
          bannerItem={bannerItem}
        />
        // <></>
      )}

      {/* <Sticky>
        {({ style }) => (
          <> */}
      {/* bannerNav */}
      <BannerMenu bannerNav={bannerNav} />
    </>
    //     )}
    //   </Sticky>
    // </>
  )
}

export default BannerNav
