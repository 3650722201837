import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function HealthTech(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="healthtech">
        <Container>
          <div className="esewa-header clearfix">
            <div className="logo mb-5">
              <img
                src="/img/business/health-tech/logo-cogent-health.png"
                alt=""
              />
            </div>

            <div className="media">
              <ul className=" follow-us">
                <li className="facebook">
                  <a
                    href=" https://www.facebook.com/cogenthealthnepal "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
                <li className="instagram">
                  <a
                    href="https://www.instagram.com/cogenthealth/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                  </a>
                </li>
                <li className="youtube">
                  <a
                    href="https://www.youtube.com/channel/UCqW7EbwKJIhHSEYem8yPYJA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                  </a>
                </li>
                <li className="linkedin">
                  <a
                    href=" https://www.linkedin.com/company/cogent-health/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </li>
                <li className="website">
                  <a
                    href="http://www.cogenthealth.com.np/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                    <img src="/img/www.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="esewa-body">
            <p className="text-muted">
              Cogent Health is one of the fastest-growing health-tech companies
              that specializes in the development of industry-standard integrated
              health information systems in Nepal. We partner with healthcare
              institutions of any size and scale and empower them with
              best-in-class technology that helps them not just drive financial
              results but help improve patient outcomes and experience.
              <br />
              <br />
              Whether it's a tertiary-level hospital or a clinic, our ever-evolving 
              suite of product features and modules, ranging from revenue management, 
              clinical records, to patient engagement, is designed to digitize the 
              entire healthcare management value chain, streamline operations, increase 
              revenues, and enhance patient care through evidence-based decisions.
              <br />
              <br />
              We believe that our experienced team members with diverse backgrounds in 
              health, tech, and management are well-placed to take care of a healthcare 
              institution’s IT so they can focus on taking care of what matters most: patients.
            </p>
          </div>

          <div className="img-collection">
            <div className=" position-relative">
              <h5>Featured Products</h5>
              <div className="underline">
                {' '}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </div>
            </div>
            <Row>
              <Col md="3" lg="2">
                <div className="photo congent" target="_blank">
                  <img src="/img/business/health-tech/logo-5.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo" target="_blank">
                  <img src="/img/business/health-tech/2.png" alt="" />
                </div>
              </Col>
              <Col md="3">
                <div className="photo congent" target="_blank">
                  <img src="/img/business/health-tech/logo-4.png" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
export default HealthTech
