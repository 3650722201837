const businessDropdownlink = [
  {
    label: 'Fintech',
    url: '/business/fintech'
  },
  {
    label: 'Digital Wallet',
    url: '/business/digitalwallet'
  },
  {
    label: 'Payments',
    url: '/business/paymentprocessing'
  },
  {
    label: 'Health Tech',
    url: '/business/healthtech'
  },
  {
    label: 'eUtility',

    url: '/business/eticketing'
  },
  {
    label: 'eSurance',
    url: '/business/esurance'
  },
  {
    label: 'Offshoring',
    url: '/business/offshoring'
  },
  {
    label: 'Remittance',
    url: '/business/remittance'
  },
  {
    label: 'Big Data',
    url: '/business/bigdata'
  },
  {
    label: 'Web/Mobile App',
    url: '/business/web-app-development'
  },

  {
    label: 'Education',
    url: '/business/education'
  }
]

export default businessDropdownlink
