import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

function OffShoring(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="offshoring">
        <Container>
          <Row>
            <Col>
              <div className="esewa-header clearfix">
                <div className=" logo">
                  <img src="/img/business/offshoring/logo-08.png" alt="" />
                </div>

                <div className=" media ">
                  <ul className=" follow-us">
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/logicabeans"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </a>
                    </li>
                    {/* <li className="twitter">
                      <a href="https://twitter.com/f1soft">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li> */}
                    <li className="linkedin">
                      <a
                        href=" https://www.linkedin.com/in/logica-beans-111606187/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </a>
                    </li>
                    <li className="website">
                      <a
                        href="https://logicabeans.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                        <img src="/img/www.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                  Logica Beans was established in 2017 as F1Soft Group’s
                  offshoring and R&D unit. Specializing in boutique offshoring,
                  the company has continuously catered to the needs of clients
                  and entrepreneurs across the USA, Australia, Amsterdam,
                  Singapore, and the Middle East regions. Logica Beans is also
                  an in-house Research and Development (R&D) platform for the
                  F1Soft Group and has been acting as an innovation engine for
                  Nepal’s fastest-growing start-up environment and a pioneer in
                  cross-fertilizing across market boundaries.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default OffShoring
