import React from 'react'
import { Row, Col } from 'reactstrap'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FeatureList from './FeatureList'
// import './features.scss';
import AddingValue from './features/AddingValue'
import DigitizingIndustry from './features/DigitizingIndustry'
import ConnectingUser from './features/ConnectingUser'
import { Transition } from 'react-transition-group'
import { Link } from 'react-router-dom'
function Features() {
  const [isFirst, setIsFirst] = useState(true)
  const [isSec, setIsSec] = useState(false)
  const [isThird, setIsThird] = useState(false)
  const duration = 200
  // arrow
  const defaultArrowStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-in',
    transitionDelay: 0,
    position: 'absolute',
    left: 10
  }
  const defaultArrowTransition = {
    entering: { left: 50 },
    entered: { left: 50 },
    exiting: { left: 10 },
    exited: { left: 10 }
  }
  // for blue bar
  const defaultBarStyle = {
    transition: 150 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0,
    position: 'absolute',
    height: 37,
    top: 0,
    left: -20,
    opacity: 0
  }

  const defaultBarTransition = {
    entering: { left: -10, opacity: 0.5 },
    entered: { left: -10, opacity: 1 },
    exiting: { left: -20, opacity: 0.5 },
    exited: { left: -20, opacity: 0 }
  }
  // for list
  const defaultListStyle = {
    transition: 100 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0
    // animationName: 'shine',
    // animationDuration: 0.75,
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // zIndex: 2,
    // display: 'block',
    // content: '',
    // // width: 100,
    // height: 100,
    // background:
    //   'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%)',
    // transform: 'skewX(-25)',
  }
  const defaultListTransition = {
    entering: { display: 'none' },
    entered: { display: 'block' },
    exiting: { display: 'block' },
    exited: { display: 'none' }
  }

  return (
    <div className="feature-bg">
      <FeatureList />
      <div className="features d-none d-md-block ">
        <Row className="row-no-padding">
          <Col md="4">
            <div className="tab-link">
              <ul className="list-tab">
                <li
                  onMouseEnter={() => {
                    setIsFirst(true)
                    setIsSec(false)
                    setIsThird(false)
                  }}
                >
                  <Transition in={isFirst} timeout={duration}>
                    {state => {
                      return (
                        <div
                          className="list"
                          style={{
                            ...defaultArrowStyle,
                            ...defaultArrowTransition[state],
                            ...{ position: 'relative' }
                          }}
                        >
                          <div className="feature-content">
                            <div
                              className="feature-bar"
                              style={{
                                ...defaultBarStyle,
                                ...defaultBarTransition[state]
                              }}
                            ></div>
                            <Link to className="feature-list connecting">
                              Enabling Financial Access
                            </Link>
                            {/* <p className='feature-text '>
                            Connecting people to make life more fun
                          </p> */}
                            <div className="position-relative">
                              <div className="angle-button">
                                <FontAwesomeIcon icon="angle-double-right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  </Transition>
                </li>

                <li
                  onMouseEnter={() => {
                    setIsSec(true)
                    setIsFirst(false)
                    setIsThird(false)
                  }}
                >
                  <Transition in={isSec} timeout={duration}>
                    {state => {
                      return (
                        <div
                          className="list"
                          style={{
                            ...defaultArrowStyle,
                            ...defaultArrowTransition[state],
                            ...{ position: 'relative' }
                          }}
                        >
                          <div className="feature-content">
                            <div
                              className="feature-bar"
                              style={{
                                ...defaultBarStyle,
                                ...defaultBarTransition[state]
                              }}
                            ></div>

                            <Link to className="feature-list digitizing">
                              Digitizing Industries
                            </Link>
                            {/* <p className='feature-text '>
                            Acting as a digital assistance to facitate
                          </p> */}
                            <div className="position-relative">
                              <div className="angle-button">
                                <FontAwesomeIcon icon="angle-double-right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  </Transition>
                </li>

                <li
                  onMouseEnter={() => {
                    setIsThird(true)
                    setIsFirst(false)
                    setIsSec(false)
                  }}
                >
                  <Transition in={isThird} timeout={duration}>
                    {state => {
                      return (
                        <div
                          className="list"
                          style={{
                            ...defaultArrowStyle,
                            ...defaultArrowTransition[state],
                            ...{ position: 'relative' }
                          }}
                        >
                          <div className="feature-content">
                            <div
                              className="feature-bar"
                              style={{
                                ...defaultBarStyle,
                                ...defaultBarTransition[state]
                              }}
                            ></div>

                            <Link to className="feature-list adding">
                              Adding Value
                            </Link>
                            {/* <p className='feature-text '>
                            Pioneering the technological innovation to bring the
                          </p> */}
                            <div className="position-relative">
                              <div className="angle-button">
                                <FontAwesomeIcon icon="angle-double-right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  </Transition>
                </li>
              </ul>
            </div>
          </Col>
          <Col md="8">
            {/* <div className='display-img'>
                    {isFirst && <ConnectingUser  />}
                    {isSec && <DigitizingIndustry />}
                    {isThird && <AddingValue />}
                  </div> */}
            <div className="display-img">
              <Transition in={isFirst} timeout={duration}>
                {style => {
                  return (
                    <div
                      style={{
                        ...defaultListStyle,
                        ...defaultListTransition[style],
                        ...{ position: 'relative' }
                      }}
                    >
                      <ConnectingUser />
                    </div>
                  )
                }}
              </Transition>

              <Transition in={isSec} timeout={duration}>
                {style => {
                  return (
                    <div
                      style={{
                        ...defaultListStyle,
                        ...defaultListTransition[style],
                        ...{ position: 'relative' }
                      }}
                    >
                      <DigitizingIndustry />
                    </div>
                  )
                }}
              </Transition>

              <Transition in={isThird} timeout={duration}>
                {style => {
                  return (
                    <div
                      style={{
                        ...defaultListStyle,
                        ...defaultListTransition[style],
                        ...{ position: 'relative' }
                      }}
                    >
                      <AddingValue />
                    </div>
                  )
                }}
              </Transition>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Features
