import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { scrollToTop } from '../../utils/scrollToTop'

function PaymentProcessing(props) {
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="paymentprocessing">
        <Container>
          <Row>
            <Col md="12">
              <div className="esewa-header clearfix">
                <div className=" logo">
                  <img src="/img/logos/foneloan.png" alt="" />
                </div>

                <div className=" media ">
                  <ul className=" follow-us">
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/foneloan"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/foneloan/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://www.linkedin.com/company/foneloan/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </a>
                    </li>
                    <li className="website">
                      <a
                        href="https://foneloan.com.np/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="/img/www.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                  Foneloan is a platform that integrates directly with your 
                  system for fast, automated loans. There are no collateral, 
                  no paperwork, no bank visits required. Foneloan uses two 
                  main components to function:
                  <br />
                  <br />
                  <ul>
                    <li>Decision Analytics System</li>
                    <li>Loan Management System</li>
                  </ul>
                  <br />
                  The Decision Analytics of Foneloansystem to identify 
                  eligible customers and their loan limits based on criteria 
                  set by the banks. Loan Management System is used to calculate 
                  interest rates, EMI amounts, payback plan, and credit the 
                  loanee’s account on the set payment dates.
                  <br />
                  <br />
                  Foneloan is a fully automated, end-to-end loan disbursement and 
                  management system.
                  <br />
                  <br />
                  Foneloan at a Glance:
                  <br />
                  <br />
                  <ul>
                    <li>100,000 loans worth more than $15 Million Disbursed So Far</li>
                    <li>Less than 1% Non-Performing Loan (NPL) Rate</li>
                    <li>$150 Average Loan Amount</li>
                  </ul>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default PaymentProcessing
