import React from 'react'
import { Card, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import imageUrl from '../../remote-data/fetchData'

function CorporateNews(props) {
  const { newsItem } = props

  return (
    <div className="corporate-news-section">
      <div className="corporate-padding">
        <Card>
          <Link to={`/media/corporatenews/${newsItem.id}`}>
            <CardImg
              top
              width="100%"
              src={imageUrl + newsItem.cover}
              alt={newsItem.title}
            />
            <CardBody>
              <CardTitle>
                {newsItem.title}

                {/* <span onClick={}>
              {newsItem.title}
            </span> */}
              </CardTitle>
              <CardSubtitle>
                <Link to target="blank">
                  {parse(newsItem.description)}
                </Link>
              </CardSubtitle>

              {/* Feb 6, 2020 */}
              <div className="date">
                <small>
                  {dayjs(newsItem.created * 1000).format('MMM DD, YYYY')}
                </small>
              </div>
            </CardBody>
          </Link>
        </Card>
      </div>
    </div>
  )
}

export default CorporateNews
