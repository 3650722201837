import React from 'react'
import { Container, Row, Col } from 'reactstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import imageUrl from '../../remote-data/fetchData'
// import { Link, useRouteMatch } from 'react-router-dom'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing'
// import DocumentMeta from 'react-document-meta';

function CorporateNewsDetails(props) {
  // const match = useRouteMatch()
  const { newsdetailItem } = props

  // Try ends here
  return (
    <div className="news-details-section">
      {/* <DocumentMeta
        title={newsdetailItem.title}
        description={newsdetailItem.description}
        image={newsdetailItem.cover}
      /> */}

      <Container>
        <div className="newsdetail-head">
          <img src={imageUrl + newsdetailItem.cover} alt="" />
        </div>
        <div className="newsdetail-body">
          <div className="newsdetail-title">
            <h5>
              {/* <Link to={`${match.url}/${newsdetailItem.id}`}> */}
              {newsdetailItem.title}
              {/* </Link> */}
            </h5>
            <div className="newsdetail-date">
              {dayjs(newsdetailItem.created * 1000).format('MMM DD, YYYY')}
            </div>
          </div>

          <div className="newsdetail-text">
            <Row>
              <Col md="12">
                <p className="text-muted">
                  {parse(newsdetailItem.description)}
                </p>
              </Col>
              {/* <Col md="2">
                <div className="social position-relative">
                  <div className="position-absolute">
                    <span>Share</span>

                    <ul className="share-link">
                      <li className="  facebook-link">

                        <Facebook
                          link={`https://f1soft.com/media/corporatenews/${newsdetailItem.id}`}
                        />
                      </li>
                      <li className="twitter-link">
                        <Twitter
                          link={`https://f1soft.com/media/corporatenews/${newsdetailItem.id}`}
                        />
                      </li>
                      <li className="linked-link">
                        <Linkedin
                          link={`https://f1soft.com/media/corporatenews/${newsdetailItem.id}`}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            
             */}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CorporateNewsDetails
