import React from 'react'

import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import imageUrl from '../../remote-data/fetchData'
// import './corporatenews.scss';

function Corporatenews(props) {
  const { newsItem } = props

  return (
    <>
      <div className="news">
        <Link to={`/media/corporatenews/${newsItem.id}`}>
          <div className="news-box">
            <dl className="new-list">
              <dt>
                <img src={imageUrl + newsItem.cover} alt="" />
              </dt>
              <dd>
                <div className="section">
                  <div className="news-title">{newsItem.title}</div>

                  <div className="news-date">
                    {dayjs(newsItem.created * 1000).format('MMM DD, YYYY')}
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </Link>
      </div>
    </>
  )
}

export default Corporatenews
