import { specGet, specPost } from './util'
import axios from 'axios'
// import { useMutation } from "react-query";
// import { useEffect } from "react";

const imageUrl =
  (process.env.NODE_ENV === 'production'
    ? 'https://api.f1soft.com'
    : 'https://f1softapi.gograbapp.com') + '' // "/api/app/v1";

export default imageUrl

const baseURL =
  (process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_API_URL
    : '') + '' // "/api/app/v1";

const request = axios.create({
  baseURL
})

// API names used for identifying api spec
// Always use this for API names instead of direct string literals
export const ApiNames = {
  CAREER_LIST: 'CAREER_LIST',
  ORGANIZATION_LIST: 'ORGANIZATION_LIST',
  CATEGORY_LIST: 'CATEGORY_LIST',
  JOB_DETAIL: 'JOB_DETAIL',
  JOB_LIST: 'JOB_LIST',
  IMPACT: 'IMPACT',
  CORPORATE_NEWS: 'CORPORATE_NEWS',
  NEWS_LETTER: 'NEWS_LETTER',
  VIDEOS: 'VIDEOS',
  CORPORATE_NEWS_DETAIL_BY_ID: 'CORPORATE_NEWS_DETAIL_BY_ID',
  NEWS_LETTER_BY_ID: 'NEWS_LETTER_BY_ID',
  GENERATE_TOKEN: 'GENERATE_TOKEN'
}

// API specs map API names to their respective specs
const apiSpecs = {
  [ApiNames.CAREER_LIST]: specGet('/index.php/career/index'),
  [ApiNames.ORGANIZATION_LIST]: specGet('/index.php/organization/index'),
  [ApiNames.CATEGORY_LIST]: specGet('/index.php/categories/index'),
  [ApiNames.JOB_DETAIL]: specGet(`/index.php/jobs/view`),
  [ApiNames.JOB_LIST]: specGet('/index.php/jobs/index'),
  [ApiNames.IMPACT]: specGet('/index.php/impact/index?sort=-weight'),
  [ApiNames.CORPORATE_NEWS]: specGet(
    '/index.php/corporate-news/index?sort=-created'
  ),
  // [ApiNames.CORPORATE_NEWS]: specGet(
  //   '/api/index.php/corporate-news/index?sort=-created'
  // ),
  [ApiNames.NEWS_LETTER]: specGet(
    '/index.php/newsletter/index?sort=-created'
  ),
  [ApiNames.VIDEOS]: specGet('/index.php/videos/index?sort=-created'),
  [ApiNames.CORPORATE_NEWS_DETAIL_BY_ID]: specGet(
    '/index.php/corporate-news/view'
  ),
  [ApiNames.NEWS_LETTER_BY_ID]: id => specGet('/index.php/newsletter/view'),
  [ApiNames.GENERATE_TOKEN]: specPost('/index.php/oauth2/token')

  // [ApiNames.SPACES_ADD]: specPost("/spaces"),
  // [ApiNames.SPACES_VIEW]: spaceId => specGet(`/spaces/${spaceId}`),
  // [ApiNames.SPACES_UPDATE]: spaceId => specPut(`/spaces/${spaceId}`)
}

let token = ''

// 'login' is declared but its value is never read.ts(6133)
export function login() {
  // return axios
  //   .get("https://registrationapp.makingnepal.com/event/123", {
  //     grant_type: "client_credentials",
  //     client_id: "59c83f7fc8a6d6555f806ce58f97041cac9073b5",
  //     client_secret: "7081d339886a0a91280e643f6e9a1583f2bcb37c"
  //   })
  //   .then(function(response) {
  //     token = response.data.access_token;
  //     console.log("response", response);
  //   });
  return axios({
    method: 'post',
    url: '/index.php/oauth2/token',
    // '/api/index.php/oauth2/token',
    data: {
      grant_type: 'client_credentials',
      client_id: '59c83f7fc8a6d6555f806ce58f97041cac9073b5',
      client_secret: '7081d339886a0a91280e643f6e9a1583f2bcb37c'
    }
  })
    .then(function(response) {
      token = response.data.data.access_token
      // console.log("token", response);
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function fetchData(
  apiName,
  { pathValues, body, params, multipart = false } = {},
  fullResponse = false
) {
  // API: Create
  // const [
  //   mutateGenerateToken,
  //   { isLoading: isGenerateTokenLoading }
  // ] = useMutation(value => {
  //   //called conditionally vancha fetchData.js maa
  //   // const { eventId, ...rest } = value
  //   return fetchData(ApiNames.GENERATE_TOKEN, {
  //     body: {
  //       grant_type: "client_credentials",
  //       client_id: "59c83f7fc8a6d6555f806ce58f97041cac9073b5",
  //       client_secret: "7081d339886a0a91280e643f6e9a1583f2bcb37c"
  //     }
  //   });
  // });

  // useEffect(() => {
  //React Hook "useEffect" is called in function "fetchData" which is neither a React function component or a custom React Hook function.eslint(react-hooks/rules-of-hooks)
  // mutateGenerateToken().then(data => {
  //   token = data.data.access_token;
  //   console.log("token", token);
  // });
  // }, []);

  let formData
  if (multipart) {
    formData = new FormData()
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => {
          formData.append(key, v)
        })
      } else {
        formData.append(key, value)
      }
    })
  }

  let apiSpec = apiSpecs[apiName]
  if (!apiSpec) {
    const msg = 'You might have forgotten to add API spec.'
    console.error(msg)
    throw new Error(msg)
  }

  if (typeof apiSpec === 'function') {
    apiSpec = apiSpec(...pathValues)
  }

  // const f1soft = {
  //   token:
  //     process.env.NODE_ENV === "production"
  //       ? process.env.REACT_APP_API_ACCESS_TOKEN_PROD
  //       : "fd66d13c0917be913170e9e48f23ba84b3682901"
  // }; //token

  // console.log('fullResponse', fullResponse)
  if (fullResponse)
    return request({
      method: apiSpec.method,
      url: apiSpec.url,
      data: multipart ? formData : body && transformRequest(body),
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        ...(multipart && { 'content-type': 'multipart/form-data' }),

      }
    }).then(res => res)
  // .data
  else
    return request({
      method: apiSpec.method,
      url: apiSpec.url,
      data: multipart ? formData : body && transformRequest(body),
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        ...(multipart && { 'content-type': 'multipart/form-data' })
      }
    }).then(res => res.data)
}

export function transformRequest(obj) {
  // console.log(obj)
  // return obj //added because of issue schedule and other page where speakers: {} being sent rather than speakers: []
  // also issue in speakers - Need to modify or remove transformRequest from pages having mutipart: true

  return Object.entries(obj).reduce((acc, [key, value]) => {
    // console.log("v", value, typeof value)
    if (value === '') {
      acc[key] = null
    } else if (!Array.isArray(value) && value && typeof value === 'object') {
      acc[key] = transformRequest(value)
    } else {
      acc[key] = value
    }
    return acc
  }, {})
}
