import React, { Fragment } from 'react';
import BannerNav from '../../components/BannerNav';
import InfoSection from '../../components/home/InfoSection.js';
import Features from '../../components/home/Features.js';
import Corporate from '../../components/home/Corporate';

function DefaultDashboard(props) {
  return (
    <Fragment>
      <BannerNav isVideo={true} />
      <InfoSection />
      <Features />

      <Corporate />
    </Fragment>
  );
}
export default DefaultDashboard;
