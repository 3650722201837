import React, { Fragment, useEffect } from 'react'
import ProgressBar from '../../components/about/Milestone'
import { scrollToTop } from '../../utils/scrollToTop'

function Company(props) {
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <ProgressBar />
    </Fragment>
  )
}
export default Company
