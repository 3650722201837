import React, { Fragment } from 'react'
import { Container } from 'reactstrap'
import CircularTimeline from '../circular-timeline/CircularTimeline'

// background images for 2018, 2017, ...
export const bgImages = [
  '/img/about/milestones/group.jpg', // 2023
  '/img/about/milestones/f1soft.png', // 2022
  '/img/about/milestones/fonepay.jpg', // 2020
  '/img/about/milestones/2019.jpg', // 2019
  '/img/about/milestones/2018.jpg', // 2018
  '/img/about/milestones/tax.jpg', // 2017
  '/img/about/milestones/expressbanking.jpg', // 2016
  '/img/about/milestones/stevie.jpg', // 2014
  '/img/about/milestones/fncci.jpg', // 2013
  '/img/about/milestones/esewazone.jpg', // 2012
  '/img/about/milestones/esewa-e.png', // 2010
  '/img/about/milestones/esewa.png', // 2009
  '/img/about/milestones/bankex.jpg', // 2008
  '/img/about/milestones/laxmibank.png', // 2007
  '/img/about/milestones/banksmart.jpg', // 2006
  '/img/about/milestones/f1soft.png', // 2004
]

// text for 2018, 2017, ...
export const yearText = [
  <ul>{/* 2023 */}
    <li>
      Total number of Mobile Banking users reached 20 million.
    </li>
    <li>
      Fonepay processed over 10 billion transactions (NPR) in a single day.
    </li>
    <li>
      New verticals ‘JumJum’ and ‘Fonepoints’ established.
    </li>
    <li>
      Total retail merchants accepting QR Payments in the Fonepay network crossed 1.3+ million.
    </li>
    <li>
      Foneloan disbursed loans worth more than $37 Million.
    </li>
  </ul>,
  <ul>{/* 2022 */}
    <li>
      Total number of mobile banking subscribers using F1Soft’s mobile banking 
      platform crossed 18+ Million.
    </li>
    <li>
      Total number of registered users on eSewa crossed 7.5+ Million.
    </li>
    <li>
      Fonepay Payment Service became Nepal’s first mobile payment network to 
      receive Payment Service Operator (PSO) license from the central bank
    </li>
    <li>
      Total retail merchants accepting QR Payments in the fonepay network 
      crossed 1.2+ million.
    </li>
    <li>
      Foneloan and FoneNxt are established as new verticals of F1Soft Group.
    </li>
  </ul>,
  <ul>{/* 2020 */}
    <li>
      Total number of mobile banking subscribers using F1Soft’s mobile banking
      platform crossed 10 Million.
    </li>
    <li>Total number of registered users on eSewa crossed 3 Million.</li>
    <li>
      Fonepay Payment Service became Nepal’s first mobile payment network to
      receive Payment Service Operator (PSO) license from the central bank.
    </li>
    <li>
      Total merchants retail merchants accepting QR Payments in the fonepay
      network crossed 100K.
    </li>
    <li>
      FoneLoan, a digital financing solution for banks to provide short term
      instant loans through mobile banking, launched by F1Soft.
    </li>
  </ul>,
  <ul>{/* 2019 */}
    <li>Invested in the education sector by partnering with Swastik College</li>
    <li>
      Formed Esewa Money Transfer to provide international and domestic
      remittance services throughout Nepal.
    </li>
    <li>
      Fonepay Payment Service registered as a subsidiary company to function as
      a digital payment network.
    </li>
  </ul>,
  <ul>{/* 2018 */}
    <li>Invested in Healthtech with the acquisition of Cogent Health.</li>
    <li>Invested in eSurance with the acquisition of Darshantech.</li>
    <li>
      Acquired Diyalo Technologies to focus on eTicketing and utility payment
      solutions.
    </li>
    <li>Formed eXtenso Data to provide Big Data analytics and AI services.</li>
  </ul>,
  <ul>{/* 2017 */}
    <li>
      eSewa became Nepal’s first company to receive Payment Service Provider
      (PSP) license from the central bank.
    </li>
    <li>
      Logica Beans, a new subsidiary was formed to provide off-shoring and R&D
      services.
    </li>
    <li>
      F1Soft recognized as Nepal’s highest tax payer (IT Sector) by the
      Government of Nepal.
    </li>
  </ul>,
  <ul>{/* 2016 */}
    <li>
      Express Banking, an internet banking suite for corporate and retail
      banking customers, was released by F1Soft.
    </li>
  </ul>,
  <ul>{/* 2014 */}
    <li>
      F1Soft introduced ‘BankSmart’ a full-fledged mobile banking system with a
      mobile app that worked with internet and SMS.
    </li>
    <li>
      F1Soft was awarded with the International Business Award – Bronze Stevie
    </li>
  </ul>,
  <ul>{/* 2013 */}
    <li>Received the FNCCI National Service Excellence Award</li>
  </ul>,
  <ul>{/* 2012*/}
    <li>
      eSewa registered as a separate company and became F1Soft’s first wholly
      owned subsidiary. This step was taken to build eSewa as a brand and take
      it to the next level while focusing on providing exceptional service to
      consumers.
    </li>
    <li>
      eSewa introduced agent led business model with the intention to make its
      services accessible to every nook and cranny of the country.
    </li>
  </ul>,
  <ul>{/* 2010*/}
    <li>eSewa officially launched.</li>
  </ul>,
  <ul>{/* 2009*/}
    <li>
      F1Soft launch of eSewa – Nepal’s first online payment gateway. With the
      onboarding of Buddha Air as eSewa’s first merchant, air tickets could be
      purchased online for the first time in Nepal. Customers could now also
      purchase mobile talk-time and pay utility bills through eSewa. However,
      the adoption was very low.
    </li>
  </ul>,
  <ul>{/* 2008 */}
    <li>Continued partnering with many other banks to provide SMS Banking.</li>
    <li>
      Developed and launched Internet Banking for the then Bank of Asia and
      Global Bank.
    </li>
  </ul>,
  <ul>{/* 2007 */}
    <li>
      Launched SMS Banking in partnership with Laxmi Bank. It featured
      conducting banking transactions using SMS syntax codes which was probably
      the first such technology in the world.
    </li>
  </ul>,
  <ul>{/* 2006 */}
    <li>
      Started providing SMS based services as Nepal Telecom’s VAS (Value Added
      Service) provider.
    </li>
    <li>eSewa as Nepal’s first payment gateway was conceptualized.</li>
  </ul>,
  <ul>{/* 2004 */}
    <li>
      F1Soft formally registered as a software company by Biswas Dhakal.
      Provided web development, web hosting and networking services.
    </li>
  </ul>
]

function ProgressBar(props) {
  // const [isFirst, setIsFirst] = useState(true);
  // const duration = 200;

  // const defaultTimeLineTransition = {
  //   entering: {
  //     // strokeDasharray: '87.5158, 1633.63',
  //     // stroke: brandColor,
  //   },
  //   entered: {
  //     // strokeDasharray: '87.5158, 1633.63',
  //     // stroke: brandColor,
  //   },
  // };

  return (
    <Fragment>
      <Container>
        <div className="milestone">
          <CircularTimeline
            // yearIndex={yearIndex}
            bgImages={bgImages}
            yearText={yearText}
          />
        </div>
      </Container>
    </Fragment>
  )
}

export default ProgressBar
