const Methods = {
  POST: "post",
  GET: "get",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete"
};

const createSpec = method => url => ({ method, url });

export const specPost = createSpec(Methods.POST);
export const specGet = createSpec(Methods.GET);
export const specPut = createSpec(Methods.PUT);
export const specDelete = createSpec(Methods.DELETE);
export const specPatch = createSpec(Methods.PATCH);
