import React, { useEffect } from 'react'
import TheCompany from '../../components/about/TheCompany.js'
import { scrollToTop } from '../../utils/scrollToTop'

function Company(props) {
  useEffect(() => {
    scrollToTop()
  })
  return (
    // <StickyContainer>
    <>
      <TheCompany />
    </>
    // </StickyContainer>
  )
}
export default Company
