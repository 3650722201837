import React, { useState, useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css'
import { Link, useHistory, useLocation } from 'react-router-dom'
// import { NavLink } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap'
import { Transition } from 'react-transition-group'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  // NavbarBrand,
  Nav,
  NavItem,
  // NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  // DropdownItem,
  NavbarText
} from 'reactstrap'
import './topnav.scss'

const Topnav = props => {
  const location = useLocation()
  useEffect(() => {
    //to remove "page-news-details" from<body class="">   breadcrum>home (onclick)
    if (location.pathname.indexOf('/career/') !== -1)
      document.body.className = 'page-news-details'
    else document.body.className = ''
  }, [location])
  // console.log('location topnav', location)

  const [isOpen, setIsOpen] = useState(false)
  const [transitionOpen, setTransitionOpen] = useState(false)
  const [dropdownAboutOpen, setDropdownAboutOpen] = useState(false)
  const [dropdownBusinessOpen, setDropdownBusinessOpen] = useState(false)
  const [dropdownMediaOpen, setdropdownMediaOpen] = useState(false)
  const [hoverContactChange, sethoverContactChange] = useState(false)
  const [hoverBlogChange, sethoverBlogChange] = useState(false)
  const [hoverCareerChange, sethoverCareerChange] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  //nav-link bg-white transation
  const duration = 100
  const defaultStyle = {
    transition: 250 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0,
    opacity: 0,
    position: 'absolute',
    height: 0,
    left: 0,
    backgroundColor: '#000000cc',
    width: '100vw',
    color: 'white'
  }
  // bg-white  height
  const heightTransition = {
    entering: { height: 0, opacity: 0 },
    entered: { height: 390, opacity: 1 },
    exiting: { height: 100, opacity: 1 },
    exited: { height: 0, opacity: 0 }
  }
  // const defaultDropdown = {
  //   entering: { height: 0, opacity: 0 },
  //   entered: { height: 100, opacity: 1 },
  //   exiting: { height: 200, opacity: 1 },
  //   exited: { height: 0, opacity: 0 }
  // };

  // nav-item color transition
  const textDefaultStyle = {
    transition: 350 + 'ms',
    color: 'white'
  }
  const textColorTransition = {
    entering: { color: 'white' },
    entered: { color: 'white' },
    exiting: { color: 'white' },
    exited: { color: 'white' }
  }

  // dropdown-item transition after hover
  const defaultHoverStyle = {
    transition: 250 + 'ms',
    position: 'absolute',
    opacity: 0,
    top: 0,
    padding: 0
  }
  const defaultHoverDropdown = {
    entering: { top: 20, left: -20, opacity: 0, lineHeight: 0 },
    entered: { top: 40, left: -20, opacity: 1, lineHeight: 2 },
    exiting: { top: 50, left: 10, opacity: 1, lineHeight: 2 },
    exited: { top: 0, left: 0, opacity: 0, lineHeight: 0 }
  }
  // dropdown-item transaction on hoverof Business
  const businessHoverStyle = {
    transition: 350 + 'ms',
    position: 'fixed',
    opacity: 0,
    top: 60,
    transform: 'translateX(-50%)',
    left: '50%',
    right: 0,
    width: '100vw'
  }

  const businessHoverDropdown = {
    entering: { opacity: 0, paddingTop: 0, lineHeight: 0 },
    entered: { opacity: 1, paddingTop: 0, lineHeight: 2 },
    exiting: { opacity: 1, paddingTop: 0, lineHeight: 2 },
    exited: { opacity: 0, paddingTop: 0, lineHeight: 0 }
  }
  // navlink onhover color:red
  const texHoverNavLink = {
    entering: { color: 'white' },
    entered: { color: '#b22222' },
    exiting: { color: '#b22222' },
    exited: transitionOpen ? { color: 'white' } : { color: 'white' }
  }

  const history = useHistory()
  return (
    <div className="topnav topnav-menu d-none d-md-block">
      <Container>
        <Transition in={transitionOpen} timeout={duration}>
          {state => {
            return (
              <div
                style={{
                  ...defaultStyle,
                  ...heightTransition[state],
                  ...{ position: 'absoulte' }
                }}
                className="absolute"
              ></div>
            )
          }}
        </Transition>
        <Transition in={transitionOpen} timeout={duration}>
          {state => {
            return (
              <Navbar color="" light expand="md">
                {/* <NavbarBrand href="/"> */}
                <Link to="/" className="navbar-brand">
                  <Container>
                    <img src="/img/logo-200.png" alt="logo" className="logo" />
                  </Container>
                </Link>
                {/* </NavbarBrand> */}
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav
                    className="ml-auto"
                    navbar
                    onMouseEnter={() => setTransitionOpen(true)}
                    onMouseLeave={() => setTransitionOpen(false)}
                  >
                    <Transition in={dropdownAboutOpen} timeout={duration}>
                      {state => {
                        return (
                          <UncontrolledDropdown
                            isOpen={dropdownAboutOpen}
                            nav
                            style={{ postion: 'relative' }}
                            inNavbar
                            onMouseEnter={() => setDropdownAboutOpen(true)}
                            onMouseLeave={() => setDropdownAboutOpen(false)}
                          >
                            <DropdownToggle
                              nav
                              caret
                              style={{
                                // ...defaultDropdown,
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              About
                            </DropdownToggle>

                            <DropdownMenu
                              right
                              style={{
                                ...defaultHoverStyle,
                                ...defaultHoverDropdown[state],
                                ...{ position: 'absoulte' }
                              }}
                            >
                              {/* <Link
                                className="dropdown-item"
                                role="menuitem"
                                to="/about/company"
                              >
                                The Company
                              </Link> */}
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/about/company')
                                  setDropdownAboutOpen(false)
                                }}
                              >
                                The Company
                              </Link>

                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/about/ecosystem')
                                  setDropdownAboutOpen(false)
                                }}
                              >
                                Ecosystem
                              </Link>

                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/about/impact')
                                  setDropdownAboutOpen(false)
                                }}
                              >
                                Impact
                              </Link>

                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/about/milestone')
                                  setDropdownAboutOpen(false)
                                }}
                              >
                                Milestones
                              </Link>

                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/about/leadership')
                                  setDropdownAboutOpen(false)
                                }}
                              >
                                Leadership
                              </Link>
                              <br />
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      }}
                    </Transition>
                    <Transition in={dropdownBusinessOpen} timeout={duration}>
                      {state => {
                        return (
                          <UncontrolledDropdown
                            nav
                            inNavbar
                            isOpen={dropdownBusinessOpen}
                            onMouseEnter={() => setDropdownBusinessOpen(true)}
                            onMouseLeave={() => setDropdownBusinessOpen(false)}
                          >
                            <DropdownToggle
                              nav
                              caret
                              style={{
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              Business
                            </DropdownToggle>

                            <DropdownMenu>
                              <Row
                                style={{
                                  ...businessHoverStyle,
                                  ...businessHoverDropdown[state],
                                  ...{ position: 'fixed', paddingBottom: 30 }
                                }}
                              >
                                <Col md="3">
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/fintech')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Fintech
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/digitalwallet')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Digital Wallet
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push(
                                        '/business/paymentprocessing'
                                      )
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Payments
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/healthtech')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    HealthTech
                                  </Link>
                                </Col>
                                <Col md="3">
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/eticketing')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    eUtility
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/esurance')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    eSurance
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/offshoring')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Offshoring
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/remittance')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Remittance
                                  </Link>
                                </Col>
                                <Col md="3">
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/bigdata')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Big Data
                                  </Link>
                                  {/* <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push(
                                        '/business/web-app-development'
                                      )
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Web/Mobile Apps
                                  </Link> */}
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/education')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Education
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/digitallending')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Digital Lending
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => {
                                      history.push('/business/neobanking')
                                      setDropdownBusinessOpen(false)
                                    }}
                                  >
                                    Neo Banking
                                  </Link>
                                  {/* <Link
                                      className="dropdown-item"
                                      onClick={() => {
                                        history.push('/business/fintech');
                                        setDropdownBusinessOpen(false);
                                      }}
                                    >
                                      Offshoring / R&D{' '}
                                    </Link>
                                  </Col>
                                  <Col md="3">
                                    <Link
                                      className="dropdown-item"
                                      onClick={() => {
                                        history.push('/business/fintech');
                                        setDropdownBusinessOpen(false);
                                      }}
                                    >
                                      Education
                                    </Link> */}
                                </Col>
                              </Row>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      }}
                    </Transition>
                    <Transition in={dropdownMediaOpen} timeout={duration}>
                      {state => {
                        return (
                          <UncontrolledDropdown
                            nav
                            inNavbar
                            isOpen={dropdownMediaOpen}
                            onMouseEnter={() => setdropdownMediaOpen(true)}
                            onMouseLeave={() => setdropdownMediaOpen(false)}
                          >
                            <DropdownToggle
                              nav
                              caret
                              left
                              style={{
                                // ...defaultDropdown,
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              Media
                            </DropdownToggle>

                            <DropdownMenu
                              style={{
                                ...defaultHoverStyle,
                                ...defaultHoverDropdown[state],
                                ...{ position: 'absoulte' }
                              }}
                            >
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/media/corporatenews')
                                  setdropdownMediaOpen(false)
                                }}
                              >
                                Corporate News
                              </Link>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/media/newsletter')
                                  setdropdownMediaOpen(false)
                                }}
                              >
                                Newsletter
                              </Link>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/media/videos')
                                  setdropdownMediaOpen(false)
                                }}
                              >
                                Videos
                              </Link>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/media/tenders')
                                  setdropdownMediaOpen(false)
                                }}
                              >
                                Tenders
                              </Link>
                              {/* <Link
                                className="dropdown-item"
                                onClick={() => {
                                  history.push('/media/socialmedia')
                                  setdropdownMediaOpen(false)
                                }}
                              >
                                Social Media
                              </Link> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      }}
                    </Transition>
                  </Nav>
                  <Nav>
                    <Transition in={hoverContactChange} timeout={duration}>
                      {state => {
                        return (
                          <NavItem
                            isOpen={hoverContactChange}
                            onMouseEnter={() => sethoverContactChange(true)}
                            onMouseLeave={() => sethoverContactChange(false)}
                          >
                            {/* <Link
                              to="/career"
                              className="nav-link"
                              exact
                              style={{
                                // ...defaultDropdown,
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              Career
                            </Link> */}
                            <a
                              class="nav-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://career.f1soft.com/"
                              style={{
                                // ...defaultDropdown,
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              Career
                            </a>
                          </NavItem>
                        )
                      }}
                    </Transition>
                    {/* <Transition in={hoverBlogChange} timeout={duration}>
                      {state => {
                        return (
                          <NavItem
                            isOpen={hoverBlogChange}
                            onMouseEnter={() => sethoverBlogChange(true)}
                            onMouseLeave={() => sethoverBlogChange(false)}
                          >
                            <a
                              href="https://f1soft.com/blog/"
                              className="nav-link"
                              style={{
                                // ...defaultDropdown,
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              Blog
                            </a>
                          </NavItem>
                        )
                      }}
                    </Transition> */}
                    <Transition in={hoverCareerChange} timeout={duration}>
                      {state => {
                        return (
                          <NavItem
                            isOpen={hoverCareerChange}
                            onMouseEnter={() => sethoverCareerChange(true)}
                            onMouseLeave={() => sethoverCareerChange(false)}
                          >
                            <Link
                              to="/contact"
                              className=" nav-link"
                              exact
                              style={{
                                //  ...defaultDropdown,
                                ...textDefaultStyle,
                                ...textColorTransition[state],
                                ...texHoverNavLink[state]
                              }}
                            >
                              Contact
                            </Link>
                          </NavItem>
                        )
                      }}
                    </Transition>
                  </Nav>
                  <NavbarText>
                    {/* <ul className='share-media'>
                    <li className='facebook'>
                      <Link to='https://www.facebook.com/f1soft'>
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </Link>
                    </li>
                    <li className='twitter'>
                      <Link to='https://twitter.com/f1soft'>
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </Link>
                    </li>
                    <li className='linkedin'>
                      <Link to='https://www.linkedin.com/company/f1softgroup/'>
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </Link>
                    </li>
                  </ul> */}
                  </NavbarText>
                </Collapse>
              </Navbar>
            )
          }}
        </Transition>
      </Container>
    </div>
  )
}

export default Topnav
