// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import 'bootstrap/dist/css/bootstrap.css';
// import './assets/scss/style.scss';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { faClock, faImage, faStar } from '@fortawesome/free-solid-svg-icons';

// library.add(fab, faClock, faImage, faStar);
// // library.add(fab, faBed, faBars, faGripHorizontal);

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

// import 'core-js/fn/array/flat-map';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faFlag,
  faBars,
  faPlay,
  faInfo,
  faUsers,
  faChevronDown,
  faGripHorizontal,
  faTimes,
  faArrowCircleDown,
  faAngleDoubleRight,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faFlag,
  faBars,
  faPlay,
  faInfo,
  faUsers,
  faChevronDown,
  faGripHorizontal,
  faTimes,
  faArrowCircleDown,
  faAngleDoubleRight,
  faGlobe
);

require('./assets/scss/_style.scss');

require('./AppRenderer');
