import React from 'react'
import { Row, Col } from 'reactstrap'
import './digitizingindustry.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Transition } from 'react-transition-group'
import { Link } from 'react-router-dom'

function DigitizingIndustry() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [transitionFirst, setTransitionFirst] = useState(false)
  const [transitionSecond, settransitionSecond] = useState(false)
  const [transitionThird, settransitionThird] = useState(false)
  const [transitionForth, settransitionForth] = useState(false)
  const duration = 200

  // text header transition
  const headerTextStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    position: 'absolute',
    top: 0
  }
  const headerTextTransition = {
    entering: { top: -15 },
    entered: { top: -85 },
    exiting: { top: -85 },
    exited: { top: -15 }
  }
  //  text-transition
  const defaultTextStyle = {
    transition: 200 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    position: 'absolute',
    height: 0,
    opacity: 0,
    top: 0
  }
  const defaultTextTransition = {
    entering: { opacity: 0, height: 0, top: -60 },
    entered: { opacity: 1, height: 0, top: -86 },
    exiting: { opacity: 1, height: 0, top: -86 },
    exited: { opacity: 0, height: 0, top: -60 }
  }
  // arrow transition
  const defaultArrowStyle = {
    transition: 300 + 'ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: 0 + 's',
    marginLeft: 5,
    opacity: 0
  }
  const defaultArrowTransition = {
    entering: {
      marginLeft: 10,
      opacity: 0
    },
    entered: {
      marginLeft: 40,
      opacity: 1
    },
    exiting: {
      marginLeft: 35,
      opacity: 0
    },
    exited: {
      marginLeft: 5,
      opacity: 0
    }
  }

  return (
    <div className="digitizing-industry ">
      <Row className="row-no-padding">
        {/* className='row-no-padding' */}
        <Col
          className="figure"
          onMouseEnter={() => setTransitionFirst(true)}
          onMouseLeave={() => setTransitionFirst(false)}
        >
          <img src="/img/homepage/bg_healthcare.jpg" alt="" />
          <Transition in={transitionFirst} timeout={duration}>
            {state => {
              return (
                <Link to="/business/healthtech">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Informatics for improved healthcare
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Cogent Health looks to transform health information
                        management by establishing a data-driven approach
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div>
         <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => settransitionSecond(true)}
          onMouseLeave={() => settransitionSecond(false)}
        >
          <img src="/img/homepage/bg_bussewa.jpg" alt="" />
          <Transition in={transitionSecond} timeout={duration}>
            {state => {
              return (
                <Link to="/business/eticketing">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Bus Tickets go online
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        With a daily inventory of over 15K tickets, BusSewa.com
                        is Nepal’s most popular bus ticketing portal
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div> */}
      </Row>
      <Row className="row-no-padding">
        {/* className='row-no-padding'*/}
        <Col
          className="figure"
          onMouseEnter={() => settransitionThird(true)}
          onMouseLeave={() => settransitionThird(false)}
        >
          <img src="/img/homepage/bg_insurance.jpg" alt="" />
          <Transition in={transitionThird} timeout={duration}>
            {state => {
              return (
                <Link to="/business/esurance">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Automating the insurance domain
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Foneinsure helps leading insurance companies by
                        automating and digitizing their workflow and payments
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/*  </div>
       <div className='div'> */}
        <Col
          className="figure"
          onMouseEnter={() => settransitionForth(true)}
          onMouseLeave={() => settransitionForth(false)}
        >
          <img src="/img/homepage/bg_remit.jpg" alt="" />
          <Transition in={transitionForth} timeout={duration}>
            {state => {
              return (
                <Link to="/business/remittance">
                  <div className="position-relative">
                    <div className="pre-text" isOpen={isOpen} onClick={toggle}>
                      <h5
                        className="h5-flex"
                        style={{
                          ...headerTextStyle,
                          ...headerTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        Introducing eRemittance
                        <div className="position-relative">
                          <div
                            className="angle-button"
                            style={{
                              ...defaultArrowStyle,
                              ...defaultArrowTransition[state],
                              ...{ position: 'relative' }
                            }}
                          >
                            <FontAwesomeIcon icon="angle-double-right" />
                          </div>
                        </div>
                      </h5>

                      <p
                        className=""
                        style={{
                          ...defaultTextStyle,
                          ...defaultTextTransition[state],
                          ...{ position: 'relative' }
                        }}
                      >
                        With digital mode of transfers, Esewa Money Transfer
                        aims to revolutionize the way Nepalese send and receive
                        money
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }}
          </Transition>
        </Col>
        {/* </div> */}
      </Row>
    </div>
  )
}

export default DigitizingIndustry
