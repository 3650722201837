export const director = [
    {
      id: 1,
      img: '/img/about/leadership/directors/biswas-dai.jpg',
      leadername: 'Biswas Dhakal',
      leaderposition: 'president & chairperson',
      leadercompany: 'F1Soft Group',
      linkedin: 'https://www.linkedin.com/in/vdhakal/',
      text: ''
    },
    {
      id: 2,
      img: '/img/about/leadership/directors/prashuram-kunwar-chhetri.jpg',
      leadername: 'Parshuram Kunwar Chhetri',
      leaderposition: 'Chairman',
      leadercompany: 'eSewa',
      linkedin: 'https://www.linkedin.com/in/parshuram-kunwar-chhetri-22a2037b/',
      text: ''
    },
    {
      id: 3,
      img: '/img/about/leadership/sambhav-sirohiya.jpg',
      leadername: 'Sambhav Sirohiya',
      leaderposition: 'Director',
      leadercompany: 'F1Soft Group',
      linkedin: 'https://www.linkedin.com/in/sambhav-sirohiya-3136b688/',
      text: ''
    },
    {
      id: 4,
      img: '/img/about/leadership/directors/subash-sharma.jpg',
      leadername: 'Subash Sharma',
      leaderposition: 'Director',
      leadercompany: 'F1Soft Group',
      linkedin: 'https://www.linkedin.com/in/sharmasubash/',
      text: ''
    },
    {
      id: 6,
      img: '/img/about/leadership/sashin-joshi.jpeg',
      leadername: 'Sashin Joshi',
      leaderposition: 'Chairman',
      leadercompany: 'Fonepay',
      linkedin: 'https://www.linkedin.com/in/sashin-joshi-40442313',
      text: ''
    },
    {
      id: 7,
      img: '/img/about/leadership/directors/Roshan-Lamichhane.jpeg',
      leadername: 'Roshan Lamichhane',
      leaderposition: 'Director',
      leadercompany: 'eSewa',
      linkedin: 'https://www.linkedin.com/in/roshan-lamichhane-profile/',
      text: ''
    },
    {
      id: 8,
      img: '/img/about/leadership-new/Daniel-Shrestha.jpg',
      leadername: 'Daniel D. Shrestha',
      leaderposition: 'Director - Group Strategy',
      leadercompany: 'F1Soft Group',
      linkedin: 'https://www.linkedin.com/in/daniel-d-shrestha-4599971b/',
      text: ''
    }
  ]
  
  export const leadership = [
    {
        id: 1,
        img: '/img/about/leadership-new/Abhishek-Chhetri.jpg',
        leadername: 'Abhishek Chhetri',
        leaderposition: 'Chief of Staff',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/abhishek-chhetri-a72471140/)',
        text: ''
    },
    {
        id: 2,
        img: '/img/about/leadership/adwait.jpg',
        leadername: '	Adwait Ratna Tuladhar',
        leaderposition: 'Chief Marketing Officer',
        leadercompany: 'F1Soft International',
        linkedin: 'https://www.linkedin.com/in/adwait-tuladhar-10527584/',
        text: ''
    },
    {
        id: 3,
        img: '/img/about/leadership-new/Ajesh-Koirala.jpg',
        leadername: 'Ajesh Koirala',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'eSewa Money Transfer',
        linkedin: 'https://www.linkedin.com/in/ajesh-koirala-b37a6656/',
        text: ''
    },
    {
        id: 3,
        img: '/img/about/leadership-new/Akbar-Khan.jpg',
        leadername: 'Akbar Khan',
        leaderposition: 'Group Chief Information Officer',
        leadercompany: 'F1Soft International',
        linkedin: 'https://www.linkedin.com/in/akbar-khan-87948523/',
        text: ''
    },
    {
        id: 4,
        img: '/img/about/leadership/management/alina.png',
        leadername: 'Alina Tuladhar',
        leaderposition: 'Brand and Marketing Manager',
        leadercompany: 'Foneinsure',
        linkedin: 'https://www.linkedin.com/in/alina-tuladhar-b12aab113/',
        text: ''
    },
    // {
    //     id: 4,
    //     img: '/img/about/leadership-new/Ashish_Gautam.jpg',
    //     leadername: 'Ashish Gautam',
    //     leaderposition: 'Chief Business Officer',
    //     leadercompany: 'eSewa',
    //     linkedin: 'https://www.linkedin.com/in/ashish-gautam-67313111a/',
    //     text: ''
    // },
    {
        id: 5,
        img: '/img/about/leadership/Avish-Acharya.jpg',
        leadername: 'Avish Acharya',
        leaderposition: 'Group Chief Finance Officer',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/avishacharya/',
        text: ''
    },
    {
        id: 6,
        img: '/img/about/leadership-new/Basanta-Pandey.jpg',
        leadername: 'Basanta Pandey',
        leaderposition: 'Chief Finance Officer',
        leadercompany: 'Fonepay',
        linkedin: 'https://np.linkedin.com/in/accabkbp',
        text: ''
    },
    {
        id: 6,
        img: '/img/about/leadership-new/brett_king.jpg',
        leadername: 'Brett King',
        leaderposition: 'Advisor',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/brettking/',
        text: ''
    },
    {
        id: 7,
        img: '/img/about/leadership-new/Diwas-Sapkota.jpg',
        leadername: 'Diwas Sapkota',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'Fonepay',
        linkedin: 'https://www.linkedin.com/in/diwas-sapkota-6a319a143/',
        text: ''
    },
    {
        id: 7,
        img: '/img/about/leadership-new/Jagdish-Khadka.jpg',
        leadername: 'Jagdish Khadka',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'eSewa',
        linkedin: 'https://www.linkedin.com/in/jagdish-khadka-58b96255/',
        text: ''
    },
    {
        id: 7,
        img: '/img/about/leadership/kailash.jpg',
        leadername: 'Kailash Shrestha',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'Logica Beans',
        linkedin: 'https://www.linkedin.com/in/kailash-shrestha-8ba17628/',
        text: ''
    },
    {
        id: 7,
        img: '/img/about/leadership/paras-kunwar.jpg',
        leadername: 'Paras Kunwar',
        leaderposition: 'Chief Operating Officer',
        leadercompany: 'Fonepay',
        linkedin: 'https://www.linkedin.com/in/paraskunwar/',
        text: ''
    },
    {
        id: 8,
        img: '/img/about/leadership/pr-khanal.jpg',
        leadername: 'PR Khanal',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'Diyalo Technologies',
        linkedin: 'https://www.linkedin.com/in/pr-khanal/',
        text: ''
    },
    {
        id: 8,
        img: '/img/about/leadership/management/Pratima-Shrestha.jpg',
        leadername: 'Pratima Shrestha',
        leaderposition: 'Head of Operations',
        leadercompany: 'Fonepay',
        linkedin: 'https://www.linkedin.com/in/pratima-shrestha-b82463286/',
        text: ''
    },
    {
        id: 9,
        img: '/img/about/leadership/praveen-bangera1.jpg',
        leadername: 'Praveen Bangera',
        leaderposition: 'Design Advisor',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/praveenbangera/',
        text: ''
    },
    // {
    //     id: 9,
    //     img: '/img/about/leadership/management/Preeti-Chhetri.jpg',
    //     leadername: 'Preeti Chhetri',
    //     leaderposition: 'Engineering Manager',
    //     leadercompany: 'Logica Beans',
    //     linkedin: 'https://www.linkedin.com/in/preety-chhetri-a7644767/',
    //     text: ''
    // },
    {
        id: 10,
        img: '/img/about/leadership-new/Rabi-Shakya.jpg',
        leadername: 'Rabi Shakya',
        leaderposition: 'Chief Strategy Officer',
        leadercompany: 'eSewa',
        linkedin: 'https://www.linkedin.com/in/rabi-shakya-116849135/',
        text: ''
    },
    {
        id: 10,
        img: '/img/about/leadership-new/Rashik_Tuladhar.jpg',
        leadername: 'Rashik Tuladhar',
        leaderposition: 'Chief Technology Officer',
        leadercompany: 'Foneinsure',
        linkedin: 'https://www.linkedin.com/in/rashiktuladhar/',
        text: ''
    },
    {
        id: 11,
        img: '/img/about/leadership/rishikesh-dai.jpg',
        leadername: 'Rhishikesh Nepal',
        leaderposition: 'Chief Partnership Officer',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/rishinepal/',
        text: ''
    },
    {
        id: 12,
        img: '/img/about/leadership-new/Sagar-Paudel.jpg',
        leadername: 'Sagar Paudel',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'Fonepoints',
        linkedin: 'https://www.linkedin.com/in/sagar-paudel-656216164/',
        text: ''
    },
    {
        id: 12,
        img: '/img/about/leadership-new/Sagar-Sharma.jpg',
        leadername: 'Sagar Sharma',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'Foneloan',
        linkedin: 'https://www.linkedin.com/in/sagar-sharma-7a905050/',
        text: ''
    },
    {
        id: 12,
        img: '/img/about/leadership/management/Salina-Nakarmi.jpg',
        leadername: 'Salina Nakarmi',
        leaderposition: 'Head of Marketing',
        leadercompany: 'Fonepay',
        linkedin: 'https://www.linkedin.com/in/salinanakarmi/',
        text: ''
    },
    {
        id: 12,
        img: '/img/about/leadership-new/Sanat-Poudel.jpg',
        leadername: 'Sanat K. Paudel',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'FoneNXT',
        linkedin: 'https://www.linkedin.com/in/sanat-k-paudel-a60057119/',
        text: ''
    },
    {
        id: 13,
        img: '/img/about/leadership-new/Santosh-Pun.jpg',
        leadername: 'Santosh Pun',
        leaderposition: 'Asst. Chief Technology Officer',
        leadercompany: 'F1Soft International',
        linkedin: 'https://www.linkedin.com/in/santosh-pun-0a839429/',
        text: ''
    },
    {
        id: 14,
        img: '/img/about/leadership-new/Saugat-KC.jpg',
        leadername: 'Saugat K.C.',
        leaderposition: 'Chief Investment Officer',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/ca-saugat-k-c-07389356/',
        text: ''
    },
    {
        id: 15,
        img: '/img/about/leadership/management/Seema-KC.jpg',
        leadername: 'Seema KC',
        leaderposition: 'Project Manager',
        leadercompany: 'F1Soft International',
        linkedin: 'https://www.linkedin.com/in/seema-kc-450532113/',
        text: ''
    },
    {
      id: 16,
      img: '/img/about/leadership-new/Siddhant-Thakuri.jpg',
      leadername: 'Siddhant Thakuri',
      leaderposition: 'Group Chief Operating Officer',
      leadercompany: 'F1Soft Group',
      linkedin: 'https://www.linkedin.com/in/siddhant-thakuri-355971141/',
      text: ''
    },
    {
        id: 17,
        img: '/img/about/leadership/Sinnathamby-Shanmugarajah.jpg',
        leadername: 'Sinnathamby Shanmugarajah',
        leaderposition: 'Advisor',
        leadercompany: 'F1Soft Group',
        linkedin: 'https://www.linkedin.com/in/sinnathamby-shanmugarajah-0064a0b/',
        text: ''
    },
    {
        id: 17,
        img: '/img/about/leadership/management/Srijana-Baniya.jpg',
        leadername: 'Srijana Baniya',
        leaderposition: 'Senior Product Manager',
        leadercompany: 'eSewa Money Transfer',
        linkedin: 'https://www.linkedin.com/in/srijana-baniya-35685046/',
        text: ''
    },
    {
        id: 18,
        img: '/img/about/leadership-new/Subash-Thapa.jpg',
        leadername: 'Subash Thapa',
        leaderposition: 'Chief Technology Officer',
        leadercompany: 'Fonepay',
        linkedin: 'https://www.linkedin.com/in/subash-thapa-293489124/',
        text: ''
    },
    {
      id: 19,
      img: '/img/about/leadership-new/Subodh-Sharma.jpg',
      leadername: 'Subodh Sharma',
      leaderposition: 'Chief Strategy Officer',
      leadercompany: 'Fonepay',
      linkedin: 'https://www.linkedin.com/in/subodh-sharma-a9a4a35/',
      text: ''
    },
    {
      id: 20,
      img: '/img/about/leadership-new/Sumit-Das.jpg',
      leadername: 'Sumit Das',
      leaderposition: 'Chief Business Officer',
      leadercompany: 'Fonepay',
      linkedin: 'https://www.linkedin.com/in/sumit-das-672b674b/',
      text: ''
    },
    {
        id: 21,
        img: '/img/about/leadership-new/Suresh-Gautam.jpg',
        leadername: 'Suresh Gautam',
        leaderposition: 'Chief Executive Officer',
        leadercompany: 'eXtensoData',
        linkedin: 'https://www.linkedin.com/in/suresh-gautam-857b7922/',
        text: ''
    },
    {
        id: 21,
        img: '/img/about/leadership/management/Susma-Pant.jpg',
        leadername: 'Susma Pant',
        leaderposition: 'Director of Engineering',
        leadercompany: 'eXtensoData',
        linkedin: 'https://www.linkedin.com/in/susmapant/',
        text: ''
    },
    {
        id: 22,
        img: '/img/about/leadership/management/Sweta-Kapali.jpg',
        leadername: 'Sweta Kapali',
        leaderposition: 'Head of Project Management',
        leadercompany: 'Fonepay',
        linkedin: 'https://www.linkedin.com/in/sweta-kapali-74b66916/',
        text: ''
    },
    {
        id: 23,
        img: '/img/about/leadership-new/Yogesh-Bhattarai.jpg',
        leadername: 'Yogesh Bhattarai',
        leaderposition: 'Director of Engineering',
        leadercompany: 'F1Soft International',
        linkedin: 'https://www.linkedin.com/in/yogeshbhattarai/',
        text: ''
    },
  ]
  
  export const senior = [
    
  ]