import React from 'react'

import ContentLoader from 'react-content-loader'
export const VideoLoader = () => (
  <div
    className="image "
    style={{
      width: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      down: 0
    }}
  >
    <ContentLoader
      spped={1}
      width="10000"
      height="530"
      viewBox="0 0 10000 530  "
      backgroundColor="#bbb9b9"
      foregroundColor="#dfdddd"
    >
      <rect width="100%" height="100%" x="0" y="0" />
    </ContentLoader>
  </div>
)
export const SingleLoader = () => (
  <div
    className="image "
    style={{
      width: '100%'
    }}
  >
    <ContentLoader
      spped={1}
      width="10000"
      height="430"
      viewBox="0 0 10000 430  "
      backgroundColor="#bbb9b9"
      foregroundColor="#dfdddd"
    >
      <rect width="100%" height="100%" x="0" y="0" />
    </ContentLoader>
  </div>
)

export const CareercardLoader = () => (
  <div className="card card-event mb-5" style={{ paddingTop: '2rem' }}>
    <ContentLoader viewBox=" 0 0 350 480">
      <rect width="200" height="30" x="10" y="60" />
      <rect width="200" height="19" x="10" y="120" />
      <rect width="600" height="13" x="10" y="180" />
      <rect width="600" height="13" x="10" y="220" />
      <rect width="600" height="13" x="10" y="260" />
      <rect width="600" height="13" x="10" y="300" />
      <rect width="600" height="13" x="10" y="320" />
      <rect width="100" height="40" x="10" y="370" />
    </ContentLoader>
  </div>
)
// homepage SuccessStories Carousel
export const SuccessstoriesLoader = () => (
  <div className="card card-event mb-5">
    <ContentLoader width={'100%'} viewBox="0 0 350 320">
      <rect width="100%" height="1500px" x="0" y="0" />
    </ContentLoader>
  </div>
)
// about>Impact
export const ImpactLoader = () => (
  <div className="image mb-4" style={{ width: '100%', marginTop: '5px' }}>
    <ContentLoader width={'100%'} viewBox="0 0 350 320">
      <rect width="100%" height="451px" x="0" y="0" />
    </ContentLoader>
  </div>
)
// media section
export const NewsLetterLoader = () => (
  <div
    className="card card-event mb-4"
    // style={{ backgroundColor: 'red', marginBottom: '1rem', padding: '1rem' }}
  >
    <ContentLoader viewBox="0 0 350 320">
      <rect width="600" height="250" x="0" y="0" />

      <rect width="600" height="13" x="10" y="260" rx="1" />

      <rect width="100" height="19" x="10" y="290" />
    </ContentLoader>
  </div>
)

export const MediaCorporatenewsLoader = () => (
  <div
    className="card card-event mb-4"
    // style={{ paddingTop: '2rem' }}
  >
    <ContentLoader viewBox=" 0 0 350 480">
      <rect width="600" height="280" x="0" y="0" />
      <rect width="200" height="19" x="10" y="320" />
      <rect width="600" height="13" x="10" y="360" />
      <rect width="600" height="13" x="10" y="380" />
      <rect width="600" height="13" x="10" y="400" />
      <rect width="100" height="19" x="10" y="450" />
    </ContentLoader>
  </div>
)

//  default MediaCorporatenewsLoader;
const CorporateNewsLoader = () => (
  // <div
  //   style={{ backgroundColor: 'red', marginBottom: '1rem', padding: '1rem' }}
  // >
  <ContentLoader>
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="250" height="13" />
  </ContentLoader>
)

export default CorporateNewsLoader
