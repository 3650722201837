import React, { Fragment, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Impact from '../../components/about/Impact'
import { ApiNames, fetchData } from '../../remote-data/fetchData'
import { useQuery } from 'react-query'
import { ImpactLoader } from '../../utils/shimmer'
import { scrollToTop } from '../../utils/scrollToTop'
// import impact from '../../data/about/impact';

function Company(props) {
  useEffect(() => {
    scrollToTop()
  })
  //API: GET
  const { status, data: impact, isLoading: impactLoading } = useQuery(
    ApiNames.IMPACT,
    () =>
      fetchData(ApiNames.IMPACT, {
        param: { size: 100 }
      })
  )
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        {(status === 'loading' || impactLoading) && <ImpactLoader />}
      </div>
      <div className="impact">
        <div className="impact-profile">
          <Row className="row-no-padding">
            {impact &&
              impact.data.map(impactItem => (
                <Col md="6">
                  <Impact impactItem={impactItem} isLoading={impactLoading} />
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
export default Company
