import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import FinTech from './fintech'
import DigitalWallet from './digitalwallet'
import PaymentProcessing from './paymentprocessing'
import HealthTech from './healthtech'
import Eticketing from './eticketing'
import Esurance from './esurance'
import OffShoring from './offshoring'
import Remittance from './remittance'
import Bigdata from './bigdata'
// import WebappDevelopment from './web-app-development'
import Education from './education'
import DigitalLending from './digitallending'
import NeoBanking from './neobanking'
import { useRouteMatch } from 'react-router-dom'
// import Page from 'react-page-loading'
import StickyLayout from '../../layout/StickyLayout'
const bannerItem = {
  image: '/img/bg_business.jpg',
  title: 'Our Business',
  caption:
    'Building an ecosystem of digital products and services to enable Nepal’s digital transformation'
}

const bannerNav = [
  {
    name: 'Fintech',
    url: '/business/fintech',
    ref: 'fintech'
  },
  {
    name: 'Digital Wallet',
    url: '/business/digitalwallet',
    ref: 'digitalwallet'
  },
  {
    name: 'Payments',
    url: '/business/paymentprocessing',
    ref: 'paymentprocessing'
  },
  {
    name: 'Health Tech',
    url: '/business/healthtech',
    ref: 'healthtech'
  },
  {
    name: 'eUtility',

    url: '/business/eticketing',
    ref: 'eticketing'
  },
  {
    name: 'eSurance',
    url: '/business/esurance',
    ref: 'esurance'
  },
  {
    name: 'Offshoring',
    url: '/business/offshoring',
    ref: 'offshoring'
  },
  {
    name: 'Remittance',
    url: '/business/remittance',
    ref: 'remittance'
  },
  {
    name: 'Big Data',
    url: '/business/bigdata',
    ref: 'bigdata'
  },
  // {
  //   name: 'Web/Mobile App',
  //   url: '/business/web-app-development',
  //   ref: 'development'
  // },

  {
    name: 'Education',
    url: '/business/education',
    ref: 'education'
  },
  {
    name: 'Digital Lending',
    url: '/business/digitallending',
    ref: 'digitallending'
  },
  {
    name: 'Neo Banking',
    url: '/business/neobanking',
    ref: 'neobanking'
  }
]

const Dashboards = () => {
  const match = useRouteMatch()
  // console.log(match);
  const pageLoader = {
    position: 'absolute',
    left: 0,
    top: 200
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="dashboard-wrapper">
      <div
        className="page-loader"
        style={{ ...pageLoader, ...{ position: 'relative' } }}
      >
        {/* <Page loader={'rotate-spin'} color={'#b22222'} size={3} duration={2} /> */}
      </div>

      <StickyLayout bannerItem={bannerItem} bannerNav={bannerNav}>
        <Switch>
          <Route path={`${match.path}/fintech`} component={FinTech} />
          <Route path={`${match.path}/digitalwallet`} component={DigitalWallet}/>
          <Route path={`${match.path}/paymentprocessing`} component={PaymentProcessing}/>
          <Route path={`${match.path}/healthtech`} component={HealthTech} />
          <Route path={`${match.path}/eticketing`} component={Eticketing} />
          <Route path={`${match.path}/esurance`} component={Esurance} />
          <Route path={`${match.path}/offshoring`} component={OffShoring} />
          <Route path={`${match.path}/remittance`} component={Remittance} />
          <Route path={`${match.path}/bigdata`} component={Bigdata} />
          {/* <Route path={`${match.path}/web-app-development`} component={WebappDevelopment}/> */}
          <Route path={`${match.path}/education`} component={Education} />
          <Route path={`${match.path}/digitallending`} component={DigitalLending} />
          <Route path={`${match.path}/neobanking`} component={NeoBanking} />
          {/* <Route path={`${match.path}/:id/add`} component={AddMobileApps} /> */}
          <Redirect to="/error" />
          {/* <Redirect exact from={`${match.path}/`} to={`${match.path}/fintech`} />
        <Route path={`${match.path}/fintech`} component={FinTech} /> */}
        </Switch>
      </StickyLayout>
    </div>
  )
}
export default Dashboards
