import React from 'react';

export default function Holder(props) {
  const { width, height } = props;
  const svgWidth = width ? width : 200;
  const svgHeight = height ? height : 200;
  const svgFill = '#000000';
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      preserveAspectRatio="none"
      style={{ width: '100%', height: '100%', opacity: 0 }}
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <g id="holder">
        <rect width={svgWidth} height={svgHeight} fill={svgFill}></rect>
        <g>
          <text x="0" y="0">
            {' '}
          </text>
        </g>
      </g>
    </svg>
  );
}
