import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import defaultDash from './default';
import { useRouteMatch } from 'react-router-dom';

const Dashboards = () => {
  const match = useRouteMatch();
  // console.log(match)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dashboard-wrapper">
      <Switch>
        {/* <Redirect exact from={`${match.path}/`} to={`${match.path}/default`} /> */}
        <Route path={`${match.path}`} component={defaultDash} />
        {/* <Route path={`${match.path}/:id/add`} component={AddMobileApps} /> */}
        <Redirect to="/error" />
      </Switch>
    </div>
  );
};
export default Dashboards;
