import React from 'react'
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function ContactdetailCard(props) {
  const { contactItem } = props
  return (
    <div className="contactdetail-section">
      <Card>
        <CardImg
          top
          width="100%"
          src={contactItem.img}
          alt={contactItem.companyname}
        />
        <CardBody>
          <CardTitle>{contactItem.companyname}</CardTitle>
          <div className="links">
            <ul>
              {contactItem.fb && (
                <li className="facebook">
                  <a
                    href={contactItem.fb}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </li>
              )}
              {contactItem.twitter && (
                <li className="twitter">
                  <a
                    href={contactItem.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </a>
                </li>
              )}
              {contactItem.linkedin && (
                <li className="linkedin">
                  <a
                    href={contactItem.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </li>
              )}
              {contactItem.insta && (
                <li className="instagram">
                  <a
                    href={contactItem.insta}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                  </a>
                </li>
              )}
              {contactItem.youtube && (
                <li className="youtube">
                  <a
                    href={contactItem.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                  </a>
                </li>
              )}
              {contactItem.website && (
                <li className="website">
                  <a
                    href={contactItem.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/img/www.svg" alt="" />
                  </a>
                </li>
              )}
            </ul>
          </div>

          <CardText>
            <ul>
              <li>{contactItem.companyaddress}</li>
              {contactItem.companyaddresssub && (
                <li>{contactItem.companyaddresssub}</li>
              )}

              {contactItem.telephone && <li>T: {contactItem.telephone}</li>}

              {contactItem.customersupp && (
                <li>Customer Support: {contactItem.customersupp}</li>
              )}

              {contactItem.trollfree && (
                <li>Troll-Free: {contactItem.trollfree}</li>
              )}
              {contactItem.viber && <li>Viber: {contactItem.viber}</li>}
              {contactItem.whatsapp && <li>WhatsApp: {contactItem.whatsapp}</li>}
              {contactItem.email1 && <li>Email: {contactItem.email1}</li>}
              {contactItem.email2 && <li>Email: {contactItem.email2}</li>}
              {/* {contactItem.website1 && <li>Website: {contactItem.website1}</li>} */}
            </ul>
          </CardText>
        </CardBody>
      </Card>
    </div>
  )
}

export default ContactdetailCard
