import React, { Component, lazy, Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import './Applayout.scss'

import TopNav from '../containers/navs/Topnav'
// import Footer from '../containers/navs/Footer';
import MobileMenu from '../containers/navs/MobileMenu'
const Footer = lazy(() => import('../containers/navs/Footer'))

class AppLayout extends Component {
  render() {
    const { containerClassnames } = this.props
    return (
      <div id="app-container" className={containerClassnames}>
        <MobileMenu />
        <TopNav history={this.props.history} />
        <main className="main-container">
          <div>{this.props.children}</div>
        </main>
        <Suspense fallback={<div className="loading" />}>
          <Footer />
        </Suspense>
      </div>
    )
  }
}
export default withRouter(AppLayout)
