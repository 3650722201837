const mediaDropdownlink = [
  {
    label: 'Corporate News',
    url: '/media/corporatenews'
  },
  {
    label: 'Newsletter',
    url: '/media/newsletter'
  },
  {
    label: 'Videos',
    url: '/media/videos'
  }
]

export default mediaDropdownlink
