import React from 'react'
import { Card, CardImg, CardLink, CardBody, CardTitle } from 'reactstrap'
// import imageUrl from '../../remote-data/fetchData'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs'
function CorporateNews(props) {
  const { newsletterItem, imageUrl } = props
  return (
    <div className="news-letter-section">
      <a
        href={imageUrl + newsletterItem.file}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card>
          <CardImg
            top
            width="100%"
            src={imageUrl + newsletterItem.cover}
            alt={newsletterItem.title}
          />

          <CardBody>
            <CardTitle>
              {newsletterItem.title}&nbsp; &nbsp;
              {newsletterItem.issue_number && newsletterItem.issue_number}&nbsp;
              &nbsp;
              {newsletterItem.created &&
                dayjs(newsletterItem.created * 1000).format('MMM DD YYYY')}
            </CardTitle>
            <CardLink>{newsletterItem.descrition}</CardLink>

            {newsletterItem.issue_number && (
              <div className="date">
                <small> Issue {newsletterItem.issue_number} </small>
              </div>
            )}
          </CardBody>
        </Card>
      </a>
    </div>
  )
}

export default CorporateNews
