import React from 'react'
// import { Loader } from '../../../src/utils/common';
import { Button } from 'reactstrap'
import CustomImg from '../common/CustomImg'
// import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
import imageUrl from '../../remote-data/fetchData'

function Company(props) {
  const { impactItem } = props
  // if (!impactItem) return <Loader />;
  return (
    <>
      {/* <Link to={`/media/impact/${impactItem.id}`}> */}
      <div className="impact-item d-none d-md-block">
        {/* <img src={impactItem.img} alt={impactItem.impactname} /> */}
        <CustomImg url={imageUrl + impactItem.cover} />
        <div className="profile position-relative">
          <div className="profile-detail position-absolute">
            <p className="name">{impactItem.message_by}</p>
            <p className="address">{impactItem.designation}</p>
          </div>
          <div className="position-relative">
            <div className="stories">
              <span>{parse(impactItem.message)}</span>
              <div className="stories-video">
                {impactItem.video && (
                  <a
                    href={impactItem.video}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button outline color="danger">
                      Watch Video
                    </Button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile-view */}

      <div className="impact-item-sm d-md-none">
        <CustomImg url={imageUrl + impactItem.cover} />
        <div className="position-relative">
          <div className="impact-section">
            <p className="message-by">
              {impactItem.message_by}
              <span>{impactItem.designation}</span>
            </p>
            <span className="message">{parse(impactItem.message)}</span>
            <p className="impact-video">
              {impactItem.video && (
                <a
                  href={impactItem.video}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button outline color="danger">
                    Watch Video
                  </Button>
                </a>
              )}
            </p>

            <span></span>
          </div>
        </div>
      </div>
    </>
  )
}
export default Company
