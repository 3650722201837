// import React, { Component } from "react";
// import { Redirect } from "react-router-dom";

// class Main extends Component {
//   render() {
//     return <Redirect to="/app" />;
//   }
// }
// export default Main;
import React, {
  Component,
  // lazy,
  Suspense
} from 'react'
import { Route, withRouter, Switch, Redirect } from 'react-router-dom'
import AppLayout from '../layout/AppLayout'
// import BlankPage from "./blank-page";

// import Page from 'react-page-loading'
import Homepage from './homepage'
import About from './about'
import Business from './business'
import Media from './media'
import Career from './career'
import Contact from './contact'
// const Homepage = lazy(() => import('./homepage'))
// const About = lazy(() => import('./about'))
// const Business = lazy(() => import('./business'))
// const Media = lazy(() => import('./media'))
// const Career = lazy(() => import('./career'))
// const Contact = lazy(() => import('./contact'))

class App extends Component {
  render() {
    // const { match } = this.props;
    const pageLoader = {
      position: 'absolute',
      left: 0,
      top: 200
    }
    return (
      <AppLayout>
        <div
          className="page-loader"
          style={{ ...pageLoader, ...{ position: 'relative' } }}
        >
          {/* <Page
            loader={'rotate-spin'}
            color={'#b22222'}
            size={3}
            duration={2}
          /> */}
        </div>
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} /> */}

            <Route path={`/about`} component={About} />
            <Route path={`/business`} component={Business} />
            <Route path={`/media`} component={Media} />
            {/*<Route path={`/career`} component={Career} /> */}
            <Route
              path={`/career`}
              component={() => {
                window.location.href =
                  'https://career.f1soft.com/'
                return null
              }}
            />
            <Route path={`/contact`} component={Contact} />

            <Route path={`/`} component={Homepage} />

            <Redirect to="/error" />
          </Switch>
        </Suspense>
      </AppLayout>
    )
  }
}

export default withRouter(App)
