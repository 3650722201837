import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import defaultDash from './default'
import { useRouteMatch } from 'react-router-dom'
// import Page from 'react-page-loading'
const Dashboards = () => {
  const match = useRouteMatch()
  // console.log(match)
  const pageLoader = {
    position: 'absolute',
    left: 0,
    top: 200
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="dashboard-wrapper">
      <div
        className="page-loader"
        style={{ ...pageLoader, ...{ position: 'relative' } }}
      >
        {/* <Page loader={'rotate-spin'} color={'#b22222'} size={3} duration={2} /> */}
      </div>
      <Switch>
        {/* <Redirect exact from={`${match.path}/`} to={`${match.path}/default`} /> */}
        <Route path={`${match.path}`} component={defaultDash} />
        {/* <Route path={`${match.path}/:id/add`} component={AddMobileApps} /> */}
        <Redirect to="/error" />
      </Switch>
    </div>
  )
}
export default Dashboards
