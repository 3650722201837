import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Twitter, Facebook, Linkedin } from 'react-social-sharing';
import { scrollToTop } from '../../utils/scrollToTop'

// import BannerBusiness from './BannerBusiness'

function FinTech(props) {
  // const currentPageUrl = window.location.href;
  useEffect(() => {
    scrollToTop()
  })
  return (
    <Fragment>
      <div className="education">
        <Container>
          <Row>
            <Col>
              <div className="esewa-header clearfix">
                <div className="logo">
                  <img src="/img/logos/logo-11.png" alt="" />
                </div>

                <div className="media ">
                  <ul className=" follow-us">
                    <li className="facebook">
                      <a
                        href=" https://www.facebook.com/swastikcollegeofficial"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                      </a>
                    </li>
                    {/* <li className="twitter">
                      <a href="https://twitter.com/f1soft">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </a>
                    </li> */}
                    <li className="linkedin">
                      <a
                        href="https://www.linkedin.com/school/swastik-college/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                      </a>
                    </li>
                    <li className="website">
                      <a
                        href="https://swastikcollege.edu.np/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <FontAwesomeIcon icon={['fas', 'globe']} /> */}
                        <img src="/img/www.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="esewa-body">
                <p className="text-muted">
                  Swastik College, the School of Computer Science and
                  Application, was founded in 2013 by a team of academicians and
                  young entrepreneurs. The institution is located at Gattaghar,
                  Bhaktapur. Currently, Swastik College offers degrees in
                  Bachelor of Science in Computer Science and Information
                  Technology (B.Sc CSIT) and Bachelor of Arts in Computer
                  Application (BCA). F1Soft Group invested in Swastik College in
                  2019 mainly to produce highly competent and industry-ready IT
                  graduates by introducing a more practical approach to the
                  curriculum.
                  <br />
                  <br />
                </p>
              </div>

              <div className="courses ">
                <ul>
                  <h4>Courses Offered</h4>
                  <li>
                    <span>B.Sc CSIT</span>
                  </li>
                  <li>
                    <span>BCA</span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default FinTech
